import React, { useState, useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { getUser } from '../Store/Auth';
import { getSettings } from '../Store/Settings';
import { getProject, saveProject, setProjects } from '../Store/Project';
import { Collapse, Navbar, NavbarToggler, NavItem } from 'reactstrap';
import Login from '../Modals/Login';
import Registration from '../Modals/Registration';
import AddProject from '../Components/Controls/AddProject';
import Search from '../Components/Controls/Search';
//import { HiOutlineUserCircle } from "react-icons/hi";
import { FaUserCircle } from "react-icons/fa";
import { IconContext } from "react-icons";
import SettingsContext from "../Components/SettingsContext";
import { SettingsContextParam } from "../Models/Requests/SettingsContextParam";
import SharingContext from "../Components/SharingContext";
// import AnalyticsContext from './AnalyticsContext';
import ContainerContext from "../Components/ContainerContext";
import { SharingContextParam } from "../Models/Requests/SharingContextParam";
import { ContainerContextParam } from "../Models/Requests/ContainerContextParam";
import { AnalyticsContextParam } from '../Models/Requests/AnalyticsContextParam';
//import { AnalyticsCardContextParam } from '../Models/Requests/AnalyticsCardContextParam';
import { FiSearch, FiFolderPlus } from "react-icons/fi";
import { MdOutlineAddChart } from "react-icons/md";
import { RiUserSharedLine, RiQuestionMark } from "react-icons/ri";
import Avatar from "../Components/Avatar";
import { TbDeviceAnalytics } from "react-icons/tb";
//import { format } from 'date-fns';
//import { convertNeSwToNwSe } from 'google-map-react';
//import { Project } from '../Models/Project';

function Navigation() {

    const [isOpen, setIsOpen] = useState(false);
    const user = useAppSelector(getUser);
    const settings = useAppSelector(getSettings);
    const projectState = useAppSelector(getProject);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    //const navigate = useNavigate();
    const style = (isOpen) ? { marginTop: "20px", marginBottom: "20px" } : {};
    const [showLogin, setShowLogin] = useState(false);
    const [showRegistration, setShowRegistration] = useState(false);
    const [logoPath, setLogoPath] = useState("/");
    const [projectName, setProjectName] = useState("");
    const [projectId, setProjectId] = useState(0);
    const [projectMode, setProjectMode] = useState("");
    const [width, setWidth] = useState(0);
    const [settingsContextParams, setSettingsContextParams] = useState<SettingsContextParam>();
    const [sharingContextParams, setSharingContextParams] = useState<SharingContextParam>();
    const [analyticsContextParams, setAnalyticsContextParams] = useState<AnalyticsContextParam>();
    //const [analyticsCardContextParams, setAnalyticsCardContextParams] = useState<AnalyticsCardContextParam>();
    const [containerContextParams, setContainerContextParams] = useState<ContainerContextParam>();
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [showShare, setShowShare] = useState<boolean>(false);
    //const [showHelp, setShowHelp] = useState<boolean>(false);
    const [showAddProject, setShowAddProject] = useState<boolean>(false);
    const [showContainer, setShowContainer] = useState<boolean>(false);
    const [showAnalytics, setShowAnalytics] = useState<boolean>(false);

    //const [content, setContent] = useState('');
    const rSpan = useRef(null);

    useEffect(() => {
        //if (user.accessToken !== "") {
         //   setLogoPath("/members");
        //}else {
            setLogoPath("/");
        //}
        setSettingsContextParams(undefined);
        setSharingContextParams(undefined);
        setAnalyticsContextParams(undefined);
       // setAnalyticsCardContextParams(undefined);
        setContainerContextParams(undefined);
    }, [user.accessToken]);

    useEffect(() => {
        let activeProject = projectState.projects.find((p) => p.loaded);
        if (activeProject) {
            setProjectMode(activeProject.mode);
            setProjectId(activeProject.id);
            setProjectName(activeProject.name);
        } else {
            setProjectMode("");
            setProjectId(0);
            setProjectName("");
        }

        /* If container was loaded, unhighlight the container icon */
        if (projectState.status === "projectUnloaded") {
            setShowContainer(false);
        }

    }, [projectState.projects]);

    const toggleLogin = (visible: boolean) => {
        setShowRegistration(false);
        setShowLogin(visible);
    }

    const toggleRegistration = (visible: boolean) => {
        setShowLogin(false);
        setShowRegistration(visible);
    }

    const handleProjectModeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setProjectMode(e.target.value);
        saveLoadedProject(projectId, projectName, true, e.target.value);
    }

    const saveProjectName = (e: React.KeyboardEvent<HTMLSpanElement>, id: number, name: string, loaded: boolean, mode: string) => {
        let projectName = (rSpan.current! as HTMLSpanElement).innerText;
        if (e.type === "keydown") {
            let event = e as React.KeyboardEvent<HTMLSpanElement>;
            if (['Enter'].indexOf(event.key) !== -1) {
                e.preventDefault();
                e.currentTarget.blur();
                setProjectName(projectName);
                saveLoadedProject(projectId, projectName, true, projectMode);
            }
        }
    }

    const saveProjectNameOnBlur = (e: React.FocusEvent<HTMLSpanElement>, id: number, name: string, loaded: boolean, mode: string) => {
        let projectName = (rSpan.current! as HTMLSpanElement).innerText;
        setProjectName(projectName);
        saveLoadedProject(projectId, projectName, true, projectMode);
    }

    const saveLoadedProject = (id: number, name: string, loaded: boolean, mode: string) => {
        dispatch(saveProject({id: id, name: name, loaded: loaded, mode: mode, event: "save" }));
        let projects = projectState.projects.map(p => {
            if (p.id === projectId) {
                let project = {...p};
                project.name = name;
                project.loaded = loaded;
                project.mode = mode;
                return project;
            }
            return p;
        });
        dispatch(setProjects(projects));
    }

    const hideAddProject = () => {
        setShowAddProject(false);
    }

    const handleShowAddProject = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setShowShare(false);
        setShowSearch(false);
        setShowAnalytics(false);
        closeContainer();
        setShowAddProject(!showAddProject);
    }

    const handleShowSearch = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setShowShare(false);
        setShowAddProject(false);
        setShowAnalytics(false);
        closeContainer();
        setShowSearch(!showSearch);
    }

    const handleShowShare = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setShowShare(!showShare);
        setShowAddProject(false);
        setShowAnalytics(false);
        setShowSearch(false);
        closeContainer();
        let project = projectState.projects.find(p => p.loaded);
        let container = projectState.containers.find(c => c.loaded);
        if (project || container) {
            setSharingContextParams({ id: 1, event: e, parentCloseHandler: sharingWindowClosed });
            //setSharingContextParams({ id: 1, event: e, parentCloseHandler: sharingWindowClosed, shareLinkPermission: project.shareLinkPermission }); 
        }
    }

    const handleShowAnalytics = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setShowShare(false);
        setShowSearch(false);
        setShowAddProject(false);
        closeContainer();
        setShowAnalytics(!showAnalytics);
        setAnalyticsContextParams({ id: 1, event: e, parentCloseHandler: analyticsWindowClosed });
    }
/*
    const handleShowHelp = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setShowHelp(!settings.showingHelp));
        setShowAddProject(false);
        setShowAnalytics(false);
        setShowSearch(false);
        setShowShare(false);
        closeContainer();
    }
*/
    const handleShowContainer = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setShowShare(false);
        setShowSearch(false);
        setShowAddProject(false);
        setShowAnalytics(false);
        setShowContainer(!showContainer);
        setContainerContextParams({ id: 1, event: e }); 
    }

    const popSettingsWindow = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        closeContainer();
        // old site setSettingsContextParams({ id: 1, event: e }); 
    }

    const sharingWindowClosed = () => {
        setShowShare(false);
    }

    const analyticsWindowClosed = () => {
        setShowAnalytics(false);
        //setSShare(false);
    }

    const closeContainer = () => {
        setShowContainer(false); 
        setContainerContextParams(undefined);
    }

    let project = projectState.projects.find(p => p.loaded);
    let container = projectState.containers.find(c => c.loaded);
    let projectMembers = projectState.projectMembers.filter(m => m.active);
    let currentMember = projectState.projectMembers.find(m => m.me && m.active);

    let boardMembers = projectMembers.map((m,i) => {
        let marginLeft = (i === 0) ? "10px" : "0px";
        return (
            <>
            {project?.permission?.toString() !== "observer" ?
                <>
                {m.status === "invited" && m.firstName === "" && m.lastName === "" ?
                    <div  onClick={(e) => handleShowShare(e)} 
                        style={{ cursor: "pointer", display: "inline-block", position: "relative", 
                            left: `-${i*4}px`, marginLeft: `${marginLeft}` 
                    }}>
                        <IconContext.Provider value={{ className: "imgxSmallAvatar" }}>
                            <FaUserCircle />
                        </IconContext.Provider> 
                    </div>
                :
                    <div onClick={(e) => handleShowShare(e)} 
                        style={{ cursor: "pointer", display: "inline-block", position: "relative", 
                            left: `-${i*4}px`, marginLeft: `${marginLeft}` 
                        }}>
                        <Avatar size="xsmall" projectMember={m} />
                    </div>
                }
                </> 
            :
                <>
                {m.status === "invited" && m.firstName === "" && m.lastName === "" ?
                    <div style={{ display: "inline-block", position: "relative", 
                                left: `-${i*4}px`, marginLeft: `${marginLeft}` 
                    }}>
                        <IconContext.Provider value={{ className: "imgxSmallAvatar" }}>
                            <FaUserCircle />
                        </IconContext.Provider> 
                    </div>
                :
                    <div style={{ display: "inline-block", position: "relative", 
                        left: `-${i*4}px`, marginLeft: `${marginLeft}` 
                    }}>
                        <Avatar size="xsmall" projectMember={m} />
                    </div>
                }
                </>
            }
            </>
        );  
    });

    let selectedMode = (projectMode === "kanban") ? "kanban": (projectMode === "action_plan") ? "action_plan" : "roadmap";
    let navAddProjectIconClass = (showAddProject) ? "navCircleActiveIcon" : "navCircleIcon";
    let navAnalyticsIconClass = (showAnalytics) ? "navCircleActiveIcon" : "navCircleIcon";
    let navContainerIconClass = (showContainer) ? "navCircleActiveIcon" : "navCircleIcon";
    let navShowSearchIconClass = (showSearch) ? "navCircleActiveIcon" : "navCircleIcon";
    let navShowShareIconClass = (showShare) ? "navCircleActiveIcon" : "navCircleIcon";
    //let navShowHelpIconClass = (settings.showingHelp) ? "navCircleActiveIcon" : "navCircleIcon";

    return (
        <>
            <header className="navigation bgc sticky-top">
                <Navbar className="navbar navbar-expand-md navbar-light" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <Link to={logoPath} onClick={() => setIsOpen(false)} className="text-white fw-bold fs-4 logoLink">
                        {/*<img className="pb-2 pt-2 logo-width" src="/images/herdr-logo.png" alt="herdr.io" />*/}
                        <div>
                            <img className="pb-2 pt-2" style={{ width: "50px", position: "relative", top: "5px", left: "10px" }} src="/images/herdr-orange-logo-cat-v1.png" alt="herdr.io" />
                        </div>
                        <div style={{ position: 'relative', top: '-15px', left: '10px'}}>
                            <img className="pb-2 pt-2" style={{ width: "55px" }} src="/images/herdr-white-logo-text-v1.png" alt="herdr.io" />
                        </div>
                    </Link>
                    {user.accessToken === "" &&
                        <>
                            {/* waitlist while we build product out
                            <Link className="registerButton" to="" onClick={() => toggleRegistration(true)}>
                                Get started
                            </Link>
                            */}
                            {/*<Link className="registerButton" to="" onClick={() => toggleRegistration(true)}>*/}
                            <Link className="registerButton" to="" onClick={(e) => { e.preventDefault(); window.scrollTo(0, 0); navigate("/" ); }}>
                                Join Waitlist
                            </Link>
                            {/*<Link className="loginButton" to="" onClick={() => toggleLogin(true)}>*/}
                            <Link className="loginButton" to="/login">
                                Login
                            </Link>
                            <Link className="pricingLink" to="/pricing">
                                Pricing
                            </Link>
                        </>
                    }

                    {user.accessToken !== "" && projectName !== "" &&
                        <>
                            {project?.permission?.toString() !== "admin" && project?.permission?.toString() !== "owner" ?
                                <div className="projectName">{projectName}</div>
                            :
                                <>
                                <div className="projectName">
                                     <span contentEditable ref={rSpan}
                                        onBlur={(e) => saveProjectNameOnBlur(e, projectId, projectName, true, projectMode)} 
                                        onKeyDown={(e) => saveProjectName(e, projectId, projectName, true, projectMode)}
                                     >
                                        {projectName}
                                     </span>
                                </div>
                               
                                </>
                            }
                            {/* display: none for now as I focus on making kanban best in class before moving
                                to action plan, grids, timelines, calendars, etc */}
                            <div className="projectView">
                                <select value={selectedMode} onChange={(e) => handleProjectModeChange(e)}>
                                    <option value="kanban">Kanban</option>
                                    <option value="action_plan">List</option>
                                    <option value="roadmap">Roadmap</option>
                                </select>
                            </div>
                        </>
                    }

                    {/* testing pulsing */}
                    {/*
                    {settings.showingHelp &&
                        <div className="avOuterContainer" onClick={(e) => {e.preventDefault(); e.stopPropagation(); alert("Help context goes here")}}>
                            <div className="avContainer" style={{
                                width: "50px",
                                height: "50px",
                                display: "grid",
                                placeItems: "center",
                                overflow: "hidden",
                                position: "relative",
                                left: "-20px",
                            }}>
                                <div className="avItem" style={{
                                    zIndex: "100000 ",
                                    padding: "5px"
                                }}>
                                    <div className="pulsingCircleIcon">
                                        <IconContext.Provider value={{ size: "14", color: "#fff" }}>
                                            <RiQuestionMark />
                                        </IconContext.Provider>
                                    </div>
                                </div>
                                <div className="pulsingCircle" style={{ animationDelay: "-3s" }}></div>
                                <div className="pulsingCircle" style={{ animationDelay: "-2s" }}></div>
                                <div className="pulsingCircle" style={{ animationDelay: "-1s" }}></div>
                                <div className="pulsingCircle" style={{ animationDelay: "0s" }}></div>
                            </div>
                        </div>
                    }
                    */}
                    {projectMembers.length > 1 && user.accessToken !== "" &&
                        <>{boardMembers}</>
                    }

                    <NavbarToggler onClick={() => setIsOpen(!isOpen)} className="mr-2 custom-toggler"/>
                    {/*<Collapse className="d-md-inline-flex flex-md-row-reverse" isOpen={isOpen} navbar>*/}
                    <Collapse className="d-md-inline-flex flex-md-row-reverse" isOpen={isOpen} navbar>
                    
                        {/*<ul className="navbar-nav flex-grow w-100 justify-content-center" style={style}>*/}
                        {user.accessToken === "" ?
                            <ul className="navbar-nav flex-grow w-100 justify-content-center" style={style}>
                                {/*
                                <NavItem className="mobile-nav-top-margin topNavLink">
                                    <Link to="/products">
                                        Products
                                    </Link>
                                </NavItem>
                                <NavItem className="mobile-nav-top-margin topNavLink">
                                    <Link to="/why-herdr">
                                        Why Herdr?
                                    </Link>
                                </NavItem>
                                
                                <NavItem className="mobile-nav-top-margin topNavLink">
                                    <Link to="/pricing">
                                        Pricing
                                    </Link>
                                </NavItem>
                            
                                <NavItem className="mobile-nav-top-margin topNavLink">
                                    <Link to="/team">Team</Link>
                                </NavItem>
                                
                                <NavItem className="mobile-nav-top-margin topNavLink">
                                    <a href="https://blog.herdr.io/category/how-to/">
                                        How To
                                    </a>
                                </NavItem>
                                <NavItem className="mobile-nav-top-margin topNavLink">
                                    <a href="https://blog.herdr.io">Blog</a>
                                </NavItem>      
                                */}                          
                            </ul>
                        :
                            <ul className="navbar-nav flex-grow" style={style}>
                                <NavItem className="me-2 mobile-nav-top-margin">
                                    {showSearch && <Search /> }
                                    {showAddProject && <AddProject close={hideAddProject} /> }
                                </NavItem>

                                <NavItem>
                                    <div className={navAnalyticsIconClass} onClick={(e) => handleShowAnalytics(e)}>
                                        <IconContext.Provider value={{ size: "20", color: "#fff" }}>
                                            <TbDeviceAnalytics />
                                        </IconContext.Provider>
                                    </div>
                                </NavItem>

                                <NavItem>
                                    <div className={navShowSearchIconClass} onClick={(e) => handleShowSearch(e)}>
                                        <IconContext.Provider value={{ size: "20", color: "#fff" }}>
                                            <FiSearch />
                                        </IconContext.Provider>
                                    </div>
                                </NavItem>

                                <NavItem>
                                    <div className={navAddProjectIconClass} onClick={(e) => handleShowAddProject(e)}>
                                        <IconContext.Provider value={{ size: "20", color: "#fff" }}>
                                            <MdOutlineAddChart />
                                        </IconContext.Provider>
                                    </div>
                                </NavItem>
                                
                                <NavItem>
                                    <div className={navContainerIconClass} onClick={(e) => handleShowContainer(e)}>
                                        <IconContext.Provider value={{ size: "20", color: "#fff" }}>
                                            <FiFolderPlus />
                                        </IconContext.Provider>
                                    </div>
                                </NavItem>
                                {/*
                                {(
                                    (project?.permission?.toString() === "admin" || project?.permission?.toString() === "owner") ||
                                    (container?.permission?.toString() === "admin" || container?.permission?.toString() === "owner")
                                )
                                */}
                                {(project?.permission?.toString() === "admin" || project?.permission?.toString() === "owner") &&
                                    <NavItem>
                                        {/* {project || container ? */}
                                        {project ?
                                            <div className={navShowShareIconClass} onClick={(e) => handleShowShare(e)}>
                                                <IconContext.Provider value={{ size: "20", color: "#fff" }}>
                                                    <RiUserSharedLine />
                                                </IconContext.Provider>
                                            </div>
                                        :
                                            <div className="navCircleInactiveIcon">
                                                <IconContext.Provider value={{ size: "20", color: "#fff" }}>
                                                    <RiUserSharedLine />
                                                </IconContext.Provider>
                                            </div>
                                        }
                                    </NavItem>
                                }
                                {/* eventual help framework
                                <NavItem>
                                    <div className={navShowHelpIconClass} onClick={(e) => handleShowHelp(e)}>
                                        <IconContext.Provider value={{ size: "20", color: "#fff" }}>
                                            <RiQuestionMark />
                                        </IconContext.Provider>
                                    </div>
                                </NavItem>
                                */}
                                <NavItem>
                                    <Link to="" onClick={(e) => popSettingsWindow(e)}>
                                        <Avatar size="small" projectMember={currentMember} />
                                    </Link>
                                </NavItem>
                            </ul>
                        }
                    </Collapse>
                </Navbar>
                <Login visible={showLogin} visibleHandler={toggleLogin} />
                <Registration visible={showRegistration} visibleHandler={toggleRegistration} />
            </header>
            <SettingsContext params={settingsContextParams} />
            <SharingContext params={sharingContextParams} />
            <ContainerContext params={containerContextParams} />
            {/*<AnalyticsContext params={analyticsContextParams} />*/}
        </>
    );
}

export default Navigation;