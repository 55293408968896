import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../Core/hooks';
import SubmitButton from '../Controls/SubmitButton';
import { toast } from 'react-toastify';
import { addProject, getProject, getProjects, setStatus } from '../../Store/Project';
import { getSettings } from '../../Store/Settings';

interface Props {
    close: () => void,
}

function AddProject(props: Props) {

    const project = useAppSelector(getProject);
    const settings = useAppSelector(getSettings);
    const dispatch = useAppDispatch();
    //const toastId = useRef(0);
    const [buttonVisible, setButtonVisible] = useState(false);
 
    useEffect(() => {
        if (project.status === "projectAdded") {
        //if (project.status === "idle" && toastId.current === 1) {
            //toastId.current = 0;
            setButtonVisible(false);
            dispatch(getProjects());
            //dispatch(saveSidebarSetting(true));
            props.close();
            // @ts-ignore
            document.getElementById("addProjectForm").reset();
        }
    }, [project.status]);

    const toggleButton = (e: any, show: boolean) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setButtonVisible(show);
        }
    }

    const formHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let name = e.currentTarget.projectName.value;
        if (name === "") {
            toast.error("Please enter a project name");
        } else {
            //toastId.current = 1;
            let currentContainer = project.containers.find(c => c.loaded);
            if (currentContainer) {
                dispatch(setStatus('loading'));
                dispatch(addProject({name: name, containerId: currentContainer.id}));
            } else {
                toast.error("Something went wrong");
            }
        }
    }

    return (
        <form id="addProjectForm" className="component" 
            onSubmit={(e) => formHandler(e)}
            onFocus={(e) => toggleButton(e, true)}
            onBlur={(e) => toggleButton(e, false)}
        >
            <input className="add-project" type="text" name="projectName" placeholder="+ add a board"/>
            <SubmitButton text="Add Board" className="generic-btn" visibility={buttonVisible} />
        </form>
    );
}

export default AddProject;