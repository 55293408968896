//import { useAppDispatch } from '../Core/hooks';
import { loadComponent } from '../Store/Settings';
import { loadedComponentType, loadedSubComponentType } from '../Helpers/types';
//const dispatch = useAppDispatch();
export const handleLoadComponent = (dispatch: any, e: React.MouseEvent<HTMLDivElement>, 
    type: loadedComponentType, subType?: loadedSubComponentType) => {
    e.preventDefault();
    dispatch(loadComponent({
        type: type,
        subType: (subType) ? subType : ""
    }));
}