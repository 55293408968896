import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
//import { toast } from 'react-toastify';
import { useAppSelector, useAppDispatch } from '../../Core/hooks';
import { getUser, login, setLoggingIn } from '../../Store/Auth';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';
//import Registration from '../../Modals/Registration';
//import Login from '../../Modals/Login';
import PricingTiers from '../../Components/PricingTiers';
import { isValidEmail } from '../../Helpers/isValidEmail';
import { joinWaitlist } from '../../Store/Utility';

function Home() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector(getUser);
    const [email, setEmail] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    //const toastId = React.useRef(0);
    //const [showLogin, setShowLogin] = useState(false);
    //const [showRegistration, setShowRegistration] = useState(false);
    //const [activationCode, setActivationCode] = useState<string | null>(null);
    //const [recoveryCode, setRecoveryCode] = useState<string | null>(null);
    //const [queryParams] = useSearchParams();
    // Get a specific query parameter
    

    useEffect(() => {
        window.scrollTo(0, 0);
        //setActivationCode(queryParams.get('ac'));
        //setRecoveryCode(queryParams.get('ar'));
    }, []);
/*
    useEffect(()=>{
        if (activationCode) {
            setShowLogin(true);
        }
    },[activationCode]);
*/
    useEffect(() => {
        if (user.accessToken !== "" && user.status !== "loading"){
            //dispatch(setLoggingIn(false));
            //navigate('/members/login');
            navigate('/members');
        }
    }, [user]);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }
    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
    }
    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
    }
    const handleJoinWaitlist = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {

        e.preventDefault();

        if (e.type === "keydown" ) {
            let keypress = e as React.KeyboardEvent;
            if (keypress.key !== 'Enter') {
                return;
            }            
        }
        
        if (!isValidEmail(email) || firstName === "" || lastName === "") {
            setError(true);
            setTimeout(() => setError(false), 2000);
        } else {
            setEmail("");
            setFirstName("");
            setLastName("");
            setSuccess(true);
            setTimeout(() => setSuccess(false), 2000);
            dispatch(joinWaitlist({
                firstName: firstName,
                lastName: lastName,
                email: email,
                marketingSource: "home page: top join waitlist form"
            }));
        }
    }
/*
    const toggleLogin = (visible: boolean) => {
        setShowRegistration(false);
        setShowLogin(visible);
    }
 
    const toggleRegistration = (visible: boolean) => {
        setShowLogin(false);
        setShowRegistration(visible);
    }

    const showRegistrationModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setShowRegistration(true);
    }
*/
    return (
    <>
        <Navigation />
        <div style={{ backgroundColor: "#02152a", paddingBottom: "350px" }}>
            <div className="homeSplashContainer">
                <div>
                    <div className="homeSplashLeft">
                        <h1 className="homePageHeader">
                            <strong>Empowering small teams</strong> to turn ideas into <em>successful products
                                <img src="images/squiggle.svg" className="squiggleSvg" />
                            </em>
                        </h1>
                        <p style={{ color: "#fff", fontSize: "20px", lineHeight: "28px", fontWeight: "450px" }}>
                            <strong>Use Herdr to accelerate your goals</strong>: whether a vision, product, feature set, or project.
                        </p> 
                        <div style={{ color: "#F8F9FA", marginTop: "20px", fontSize: "18px", lineHeight: "28px", fontWeight: "450px" }}  >
                            <ol className="homeSplashList">
                                <li><b>Start with an idea or goal</b></li>
                                <li>We generate your roadmap, projects, and more</li>
                                <li>Review and invite teammates</li>
                                <li>Stay on track and launch faster</li>
                            </ol>
                        </div>
                    </div>
                    <div className="homeSplashMiddle"></div>
                    <div className="homeSplashRight">
                        <div className="homeJoinWaitlistTitle">
                            Join the Waitlist
                        </div>
                        <div className="homeWaitlistBlurb" style={{ color: "#cacfd2", fontSize: "14pt", paddingTop: "10px", paddingBottom: "10px" }}>
                            Be the first to experience Herdr.io! Join our waitlist to get early access 
                            and stay updated on our launch.
                        </div>
                        {error &&
                            <div className="error" style={{ marginTop: "10px" }}>
                                Please enter all fields
                            </div>
                        }
                        {success &&
                            <div className="success" style={{ marginTop: "10px" }}>
                                You're on the list!
                            </div>
                        }
                        <input type="input" style={{ marginTop: "10px" }} className="form-control" placeholder="Email Address" 
                            value={email}
                            onChange={(e) => handleEmailChange(e)} />
                        <input type="input" style={{ marginTop: "10px" }} className="form-control" placeholder="First Name"
                            value={firstName}
                            onChange={(e) => handleFirstNameChange(e)} />
                        <input type="input" style={{ marginTop: "10px" }} className="form-control" placeholder="Last Name" 
                            value={lastName}
                            onChange={(e) => handleLastNameChange(e)} />
                        <button className="yellowButton" style={{ width: "100%", marginTop: "10px" }}
                            onClick={(e) => handleJoinWaitlist(e)} onKeyDown={(e) => handleJoinWaitlist(e)}>
                            Get Launch Updates
                        </button>
                        {/*<Registration inline={true} visible={true} visibleHandler={toggleRegistration} />*/}
                    </div>
                </div>
            </div>
        </div>  
        <div className="homeCustomerSegmentContainer">
            <div className="homeCustomerSegmentContainerInner">
                <span className="customerSegmentBullet1">SIMPLIFY PLANNING.</span>
                <span className="customerSegmentBullet2">TRACK PROGRESS.</span>
                <span className="customerSegmentBullet2">DRIVE RESULTS.</span>
            </div>
            <div className="customerSegmentContainer1">
                <Link to="/tech-startup-founders" className="homeCustomerSegmentLink">
                    <div className="customerSegmentBox1">
                        <div style={{ textTransform: "uppercase", fontSize: "10pt", paddingBottom: "10px"}}>
                            Simplify organizing ideas and planning
                        </div>
                        <h2 style={{ fontSize: "14pt", fontWeight: "bold" }}>Tech Startup Founders</h2>
                        <p>
                            Remove the complexity and stay focused and aligned, from concept to execution.	
                        </p>
                    </div>
                </Link>
                <Link to="/creative-agencies" className="homeCustomerSegmentLink">
                    <div className="customerSegmentBox2">
                        <div style={{ textTransform: "uppercase", fontSize: "10pt", paddingBottom: "10px"}}>
                            Connect big-picture goals with daily tasks
                        </div>
                        <h2 style={{ fontSize: "14pt", fontWeight: "bold" }}>Creative Agencies</h2>
                        <p>
                            Keep your team 
                            aligned and efficient, so you can focus on delivering results, 
                            not managing chaos.	
                        </p>
                    </div>
                </Link>
                <Link to="/freelancers" className="homeCustomerSegmentLink">
                    <div className="customerSegmentBox3">
                        <div style={{ textTransform: "uppercase", fontSize: "10pt", paddingBottom: "10px"}}>
                            Coordinate without the need for hierarchies
                        </div>
                        <h2 style={{ fontSize: "14pt", fontWeight: "bold" }}>Freelance Collaboratives</h2>
                        <p>
                            Keep freelancers organized and aligned, enabling smooth collaboration across projects.	
                        </p>
                    </div>
                </Link>
                <Link to="/product-designers" className="homeCustomerSegmentLink">
                    <div className="customerSegmentBox4">
                        <div style={{ textTransform: "uppercase", fontSize: "10pt", paddingBottom: "10px"}}>
                            Streamline design from ideation to delivery
                        </div>
                        <h2 style={{ fontSize: "14pt", fontWeight: "bold" }}>Product Designers and UX Teams</h2>
                        <p>
                            Keep your team organized and aligned, whether you're prototyping a mobile 
                            app or refining your latest design.	
                        </p>
                    </div>
                </Link>
            </div>

            <div className="customerSegmentContainer2">
                <Link to="/bootstrapped-entrepreneurs" className="homeCustomerSegmentLink">
                    <div className="customerSegmentBox1">
                        <div style={{ textTransform: "uppercase", fontSize: "10pt", paddingBottom: "10px"}}>
                            streamline your workflow without the complexity
                        </div>                    
                        <h2 style={{ fontSize: "14pt", fontWeight: "bold" }}>Bootstrapped Entrepreneurs</h2>
                        <p>
                            Track progress and manage tasks without the complexity, 
                            allowing you to focus on growing your business.
                        </p>
                    </div>
                </Link>
                <Link to="/small-nonprofits" className="homeCustomerSegmentLink">
                    <div className="customerSegmentBox2">
                        <div style={{ textTransform: "uppercase", fontSize: "10pt", paddingBottom: "10px"}}>
                            focus on making an impact
                        </div> 
                        <h2 style={{ fontSize: "14pt", fontWeight: "bold" }}>Small Nonprofits</h2>
                        <p>
                            Whether you're managing multiple nonprofit initiatives or coordinating community events, 
                            stay on track without added complexity.	
                        </p>
                    </div>
                </Link>
                <Link to="/academic-research-teams" className="homeCustomerSegmentLink">
                    <div className="customerSegmentBox3">
                        <div style={{ textTransform: "uppercase", fontSize: "10pt", paddingBottom: "10px"}}>
                            focus on experiments and publications
                        </div> 
                        <h2 style={{ fontSize: "14pt", fontWeight: "bold" }}>Academic Research Teams</h2>
                        <p>
                            With streamlined workflows, trackable deadlines, and easily managed tasks
                        </p>
                    </div>
                </Link>
                <Link to="/software-development-teams" className="homeCustomerSegmentLink">
                    <div className="customerSegmentBox4">
                        <div style={{ textTransform: "uppercase", fontSize: "10pt", paddingBottom: "10px"}}>
                            Support fast development without the complexity
                        </div> 
                        <h2 style={{ fontSize: "14pt", fontWeight: "bold" }}>Small Software Development Teams</h2>
                        <p>
                            Large project management platforms are too complex. 
                            Whether you're building a plugin or working on a software project, stay organized and efficient.
                        </p>
                    </div>
                </Link>
            </div>

            <div className="customerSegmentContainer3">
                <Link to="/consultants-coaches" className="homeCustomerSegmentLink">
                    <div className="customerSegmentBox1">
                        <div style={{ textTransform: "uppercase", fontSize: "10pt", paddingBottom: "10px"}}>
                            guide clients through roadmaps and tasks
                        </div> 
                        <h2 style={{ fontSize: "14pt", fontWeight: "bold" }}>Consultants and Coaches</h2>
                        <p>
                            Organize projects, track progress, and provide 
                            clear direction, making it easy to support your clients and ensure their success.
                        </p>
                    </div>
                </Link>
                <Link to="/ecommerce-teams" className="homeCustomerSegmentLink">
                    <div className="customerSegmentBox2">
                        <div style={{ textTransform: "uppercase", fontSize: "10pt", paddingBottom: "10px"}}>
                            Manage product launches and marketing campaigns
                        </div> 
                        <h2 style={{ fontSize: "14pt", fontWeight: "bold" }}>Small E-Commerce Teams</h2>
                        <p>
                            Help your team stay organized and efficient as you launch new products and drive sales.
                        </p>
                    </div>
                </Link>
                <Link to="/company-executives" className="homeCustomerSegmentLink">
                    <div className="customerSegmentBox3">
                        <div style={{ textTransform: "uppercase", fontSize: "10pt", paddingBottom: "10px"}}>
                            Manage Strategic Initiatives
                        </div> 
                        <h2 style={{ fontSize: "14pt", fontWeight: "bold" }}>Small and Mid-Size Company Executives</h2>
                        <p>
                            Keep your teams aligned and on track as you roll out new products, execute marketing 
                            campaigns, and scale operations effectively.
                        </p>
                    </div>
                </Link>
            </div>
        </div>

        <div className="homeOrangeWave">  
            <div className="homePricingTitle">
                Simplified Pricing
            </div>          
            <div className="wave">
                <svg name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                </svg>
            </div>
            <div className="homePagePricingTiersContainer">
                <PricingTiers 
                    mostPopularButtonBackgroundColor='#000' 
                    mostPopularButtonColor='#fff'
                    mostPopularButtonBorder='3px solid #e55c02' 
                />
            </div>
        </div>

        <div style={{ position: "relative", backgroundColor: "#F8F9FA" }}>
            <div style={{ textAlign: "center", width: "80%", margin: "0px auto", paddingTop: "150px", paddingBottom: "150px" }}>
                As we build out or product, stayed tuned for a showcase of the features, which will be placed here.
            </div>
        </div>

        {/*<Login visible={showLogin} visibleHandler={toggleLogin} activationCode={activationCode} recoveryCode={recoveryCode} />
        <Registration visible={showRegistration} visibleHandler={toggleRegistration} />*/}
        <Footer style={{ backgroundColor: "#02152a", position: "relative" }} />
    </>
    );
}

export default Home;