import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../Core/hooks';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';

function ConsultantCoach() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navigation />
            <div className="pageHeader">
                <div className="standardPublicPage60">
                    <h1 className="homePageHeader">
                        Simplify Client Success: A Better Way to Guide, Track, and Deliver
                    </h1>
                </div>
            </div>
            <div className="standardPublicPage60">
                <div className="standardPublicParagraph">
                    As a consultant or coach, your expertise lies in helping clients achieve 
                    their goals. But managing multiple client projects, keeping tasks on track, 
                    and delivering clear direction can quickly become overwhelming—especially 
                    when juggling different tools and workflows.  
                </div>
                <div className="standardPublicParagraph">
                    Our platform is designed to help you <b>guide clients through roadmaps and 
                    tasks</b> effortlessly. By organizing projects, tracking progress, and 
                    ensuring clarity at every step, you can focus on what matters most: 
                    <b>empowering your clients and ensuring their success</b>. 
                </div>
                <h2 className="standardPublicH2">Clear Roadmaps, Clear Results</h2>
                <div className="standardPublicParagraph"> 
                    Every client journey is unique, but clarity is universal. Map out step-by-step 
                    roadmaps for each client project, setting clear milestones and actionable tasks. 
                    Give your clients a transparent view of their progress, helping them stay 
                    motivated and aligned.  
                </div>
                <h2 className="standardPublicH2">Stay Organized Across Projects</h2>
                <div className="standardPublicParagraph"> 
                    Managing multiple clients doesn't have to mean juggling chaos. Our platform 
                    allows you to organize every client's tasks, deliverables, and timelines in 
                    one place. Easily switch between projects without missing a beat.  
                </div>
                <h2 className="standardPublicH2">Track Progress in Real Time</h2>
                <div className="standardPublicParagraph"> 
                    Keep tabs on every client's progress with a clear, up-to-date overview. 
                    Track completed tasks, identify bottlenecks, and adjust plans as needed to 
                    ensure smooth execution. No more guessing or digging through emails to 
                    find updates.  
                </div>
                <h2 className="standardPublicH2">Provide Clear Communication and Direction</h2>
                <div className="standardPublicParagraph"> 
                    Effective communication is key to client success. Use our platform to 
                    centralize feedback, share updates, and provide actionable insights. 
                    Keep clients engaged and confident with clear, concise direction at 
                    every stage of their journey.  
                </div>
                <h2 className="standardPublicH2">Flexible Tools for Your Unique Workflow</h2>
                <div className="standardPublicParagraph"> 
                    Whether you're coaching individuals or managing large consulting projects, 
                    our platform adapts to your needs. Create templates for recurring tasks, 
                    customize workflows, and tailor the experience to suit each client's goals.  
                </div>
                <h2 className="standardPublicH2">Deliver a Seamless Client Experience</h2>
                <div className="standardPublicParagraph">
                    Your clients trust you to guide them, and our platform ensures you can deliver 
                    on that promise. By simplifying project management and communication, you 
                    provide a professional, organized, and impactful experience that 
                    keeps clients coming back.  
                </div>
                <h2 className="standardPublicH2">Why Choose Us?</h2>
                <ul className="standardPublicParagraph">
                    <li><b>Intuitive and Professional:</b> Easy to use for both you and your clients.</li> 
                    <li><b>Time-Saving:</b> Automate routine tasks and focus on high-value activities.</li> 
                    <li><b>Collaboration-Friendly:</b> Keep everyone on the same page with centralized 
                        communication and updates.</li> 
                </ul>
                <div className="standardPublicParagraph">
                    From defining goals to celebrating achievements, our platform helps you guide 
                    clients through every step of their journey. Spend less time on logistics and 
                    more time driving results, ensuring every client feels supported and successful.  
                </div>
                <div className="standardPublicParagraph">
                    Ready to elevate your coaching or consulting practice?&nbsp;
                    <Link to="" onClick={(e) => { e.preventDefault(); navigate("/") }}>
                        Start your free trial today and see the difference!
                    </Link>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ConsultantCoach;