import React, { CSSProperties } from 'react';
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { getRoadmap } from '../Store/Roadmap';
import { loadComponent, getSettings, toggleHerdr } from '../Store/Settings';
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { BsBell } from "react-icons/bs";
import { LuKanban } from "react-icons/lu";
import { BiSolidLeftArrow, BiSearch } from "react-icons/bi";
import { IoChatbubbleOutline, IoMapOutline, IoSettingsOutline, IoPeopleOutline } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import { Roadmap } from '../Models/Roadmap';
import { loadedComponentType, loadedSubComponentType } from '../Helpers/types';
import { handleLoadComponent } from '../Helpers/handleLoadComponent';
import ChatSubNav from './ChatSubNav';
import { RiQuestionMark } from "react-icons/ri";

function HerdrSubNav() {

    const dispatch = useAppDispatch();
    const roadmapState = useAppSelector(getRoadmap);
    const settingsState = useAppSelector(getSettings);

    const handleToggleHerdr = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        dispatch(toggleHerdr(!settingsState.settings.herdrExpanded));
    }

    return (
        <div style={{ height: "100vh",backgroundColor: "#27282d" }}>
            <div style={{ width: "80%", margin: "0px auto", color: "#909495", paddingTop: "20px" }}>
                <div style={{ width: "10%", display: "inline-block", position: "relative", top: "-2px" }}>
                    <RiQuestionMark size={18} color="#909495" />
                </div>
                <div style={{color: "#bec1c3", width: "50%",fontSize: "12pt", display: "inline-block", paddingLeft: "7px" }}>
                    FAQ
                </div>
                <div style={{ width: "40%", display: "inline-block", textAlign: "right" }}>
                    <div style={{ display: "inline-block", position: "relative", top: "1px", paddingRight: "10px" }}>
                        <BiSearch size={16} color="#909495" />
                    </div>
                    {/*
                    <div style={{ display: "inline-block", paddingRight: "10px" }}>
                        <AiOutlinePlus size={16} color="#909495" />
                    </div>
                    */}
                    <div style={{ display: "inline-block", position: "relative", top: "1px", cursor: "pointer" }}
                        onClick={(e) => handleToggleHerdr(e)}>
                        {settingsState.settings.herdrExpanded ? 
                            <VscTriangleUp  size={18} color="#909495" />
                        :
                            <VscTriangleDown  size={18} color="#909495" />
                        }
                    </div>
                </div>
            </div>
            <div style={{ width: "80%", margin: "0px auto", color: "#909495", paddingTop: "20px" }}>
                <div>How do I create a roadmap?</div>
                <div>How do I upgrade my account?</div>
                <div>Does herdr have a mobile app?</div>
                <div>FAQ 4</div>
                <div>FAQ 5</div>
                <div>FAQ 6</div>
            </div>
            <ChatSubNav />
        </div>
    );
}

export default HerdrSubNav;