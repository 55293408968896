import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../Core/hooks';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';

function BootstrappedEntrepreneur() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navigation />
            <div className="pageHeader">
                <div className="standardPublicPage60">
                    <h1 className="homePageHeader">
                        Streamline Your Workflow and Grow Your Business
                    </h1>
                </div>
            </div>
            <div className="standardPublicPage60">
                <div className="standardPublicParagraph">
                    As a bootstrapped entrepreneur, your time is your most valuable asset. With limited 
                    resources and countless responsibilities, managing tasks and tracking progress can 
                    quickly become overwhelming. Complex tools with unnecessary features only add to the 
                    frustration, pulling your focus away from what truly matters: <b>growing your business</b>. 
                </div>
                <div className="standardPublicParagraph">
                    Our platform is designed to help you <b>streamline your workflow without the complexity</b>. 
                    Manage tasks, track progress, and stay organized so you can focus on scaling your business - 
                    not on navigating cumbersome tools.  
                </div>
                <h2 className="standardPublicH2">Simplify Task Management</h2>
                <div className="standardPublicParagraph">
                    Running a lean operation means you can't afford wasted effort. Our tool provides a clear, 
                    intuitive way to organize your to-do list, set priorities, and delegate tasks when 
                    needed. Keep everything in one place so you always know what's next.  
                </div>
                <h2 className="standardPublicH2">Track Progress at a Glance</h2>
                <div className="standardPublicParagraph"> 
                    Get a complete overview of your projects without diving into the details. With our 
                    simple tracking tools, you can monitor progress, spot roadblocks, and ensure deadlines 
                    are met—all at a glance. No complexity, just clarity.  
                </div>
                <h2 className="standardPublicH2">Adapt to Your Workflow</h2>
                <div className="standardPublicParagraph">
                    Your business is unique, and so is your workflow. Our platform is flexible enough to adapt 
                    to the way you work. Whether you're launching a product, building a team, or managing 
                    client projects, our tool helps you stay organized and efficient.  
                </div>
                <h2 className="standardPublicH2">Eliminate Complexity</h2>
                <div className="standardPublicParagraph"> 
                    Forget bloated project management tools that require a steep learning curve. We focus on 
                    giving you just the features you need, cutting through the noise so you can get more 
                    done in less time.  
                </div>
                <h2 className="standardPublicH2">Focus on Growth, Not Management</h2>
                <div className="standardPublicParagraph"> 
                    The last thing you need is a tool that slows you down. Our platform simplifies your 
                    day-to-day operations, freeing up your mental energy to focus on strategic goals. Spend 
                    less time managing and more time growing your business.  
                </div>
                <h2 className="standardPublicH2">Built for Entrepreneurs Like You</h2>
                <div className="standardPublicParagraph"> 
                    We understand the challenges of running a bootstrapped business because we've been there. 
                    That's why we created a tool that's:  
                </div>
                <ul className="standardPublicParagraph">
                    <li><b>Easy to Use:</b> Get started in minutes with an intuitive interface.</li>
                    <li><b>Lightweight and Powerful:</b> Just the right features to help you stay on track 
                    without overwhelming you.</li>
                    <li><b>Affordable:</b> Built with cost - conscious entrepreneurs in mind.</li>
                </ul>
                <div className="standardPublicParagraph">
                    Take control of your workflow, streamline your operations, and focus on achieving your business 
                    goals. Our platform is here to help you work smarter, not harder—so you can grow faster.  
                </div>

                <div className="standardPublicParagraph">
                    Ready to simplify your workflow and focus on growth?&nbsp;
                    <Link to="" onClick={(e) => { e.preventDefault(); navigate("/") }}>
                        Start your free trial today!
                    </Link>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default BootstrappedEntrepreneur;