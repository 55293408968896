import { useAppSelector } from '../Core/hooks';
import { getUser } from '../Store/Auth';
//import { getSettings } from '../Store/Settings';
import { ProjectMember } from "../Models/Requests/ProjectMember";

interface Props {
    size: string;
    projectMember?: ProjectMember;
    inline?: boolean;
    owner?: boolean;
}

function Avatar(props: Props) {

    const user = useAppSelector(getUser);
    //const settings = useAppSelector(getSettings);

    //console.log("Settings Avatar: " + settings.avatar);
    //console.log("Props Avatar: " + props.projectMember?.avatar);

    let aClass = "";
    let iClass = "";

    if (props.inline) {
        aClass = (props.size === "xxsmall") ? "iimgxxSmallAvatar" : (props.size === "xsmall") ? "iimgxSmallAvatar" : (props.size === "small") ? "iimgSmallAvatar" : "iimgAvatar";
        iClass = (props.size === "xxsmall") ? "iimgxxSmallAvatarInitials" : (props.size === "xsmall") ? "iimgxSmallAvatarInitials" : (props.size === "small") ? "iimgSmallAvatarInitials" : "iimgAvatarInitials";
    
    } else {
        aClass = (props.size === "xxsmall") ? "imgxxSmallAvatar" : (props.size === "xsmall") ? "imgxSmallAvatar" : (props.size === "small") ? "imgSmallAvatar" : "imgAvatar";
        iClass = (props.size === "xxsmall") ? "imgxxSmallAvatarInitials" : (props.size === "xsmall") ? "imgxSmallAvatarInitials" : (props.size === "small") ? "imgSmallAvatarInitials" : "imgAvatarInitials";
    }

    return (
        <>
        {props.projectMember ?
            <>
             {props.projectMember.avatar !== "" && props.projectMember.avatar !== undefined ?
                <div className={aClass} style={{ backgroundImage: `url(${props.projectMember.avatar}` }}></div>
            :
                <div className={iClass}
                    style={{ backgroundColor: props.projectMember.defaultAvatarColor, 
                        color: props.projectMember.defaultAvatarFontColor
                    }}>
                    {props.projectMember.firstName.charAt(0).toUpperCase()}
                    {props.projectMember.lastName.charAt(0).toUpperCase()}
                </div>
            }
            </>
        :
            <>
            {user.avatar !== "" && user.avatar !== undefined ?
                <>
                {props.owner !== false &&
                    <div className={aClass} style={{ backgroundImage: `url(${user.avatar}` }}></div>
                }
                </>
            :
                <div className={iClass}
                    style={{ backgroundColor: user.defaultAvatarColor, 
                        color: user.defaultAvatarFontColor
                    }}>
                    {user.firstName.charAt(0).toUpperCase()}
                    {user.lastName.charAt(0).toUpperCase()}
                </div>
            }
            </>
        }
       </>
    );
}

export default Avatar;