import React, { CSSProperties } from 'react';
import { IoChatbubbleOutline, IoMapOutline } from "react-icons/io5";
import { MdWorkspacesOutline } from "react-icons/md";
import { LuKanban } from "react-icons/lu";
import { BsBell } from "react-icons/bs";
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { getRoadmap } from '../Store/Roadmap';
import { getSettings } from '../Store/Settings';
import { loadComponent } from '../Store/Settings';
import { handleLoadComponent } from '../Helpers/handleLoadComponent';

function RoadmapNav() {

    const dispatch = useAppDispatch();
    const settingsState = useAppSelector(getSettings);
    const roadmapState = useAppSelector(getRoadmap);

    let navItemStyle = { 
        cursor: "pointer",
        paddingTop: "5px", 
        fontSize: "8pt", 
        color: "#F8F9FA", 
        fontWeight: "450",
    };

    let navItemSelectedStyle = {
        cursor: "pointer",
        paddingTop: "5px", 
        fontSize: "8pt",  
        fontWeight: "450",
        color: "#e55c02",
        textDecoration: "underline",
        textUnderlineOffset: "5px",
        textDecorationThickness: "1px",
    };

    let mapsTabLoaded = settingsState.settings.loadedComponentType === "global_maps";
    let mapsIconColor = (mapsTabLoaded) ? "#e55c02" : "#bec1c3";
    let mapsDivStyle: CSSProperties = (mapsTabLoaded) ? navItemSelectedStyle : navItemStyle;

    let workTabLoaded = settingsState.settings.loadedComponentType === "global_work";
    let workIconColor = (workTabLoaded) ? "#e55c02" : "#bec1c3";
    let workDivStyle: CSSProperties = (workTabLoaded) ? navItemSelectedStyle : navItemStyle;

    let actionTabLoaded = settingsState.settings.loadedComponentType === "global_action";
    let actionIconColor = (actionTabLoaded) ? "#e55c02" : "#bec1c3";
    let actionDivStyle: CSSProperties = (actionTabLoaded) ? navItemSelectedStyle : navItemStyle;

    let herdrTabLoaded = settingsState.settings.loadedComponentType === "global_herdr";
    //let actionIconColor = (actionTabLoaded) ? "#e55c02" : "#bec1c3";
    let herdrDivStyle: CSSProperties = (herdrTabLoaded) ? navItemSelectedStyle : navItemStyle;  


    return (
        <div style={{ height: "100vh", backgroundColor: "#313338", borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px", margin: "0px auto", textAlign: "center",
            paddingTop: "15px"
         }}>
            <div style={{ backgroundColor: "#fff", borderRadius: "50%", width: "27px", height: "27px",
                margin: "0px auto", textAlign: "center", cursor: "pointer" }} 
                onClick={(e) => handleLoadComponent(dispatch, e, "global_herdr")}>
                <img src='https://herdr.io/images/orange-cat-head.jpg' style={{ width: "17px", 
                    position: "relative", top: "-2px", left: "1px"
                 }} />
            </div>
            <div style={herdrDivStyle} 
                onClick={(e) => handleLoadComponent(dispatch, e, "global_herdr")}>
                FAQ
            </div>
            <div style={{ paddingTop: "20px", cursor: "pointer" }} onClick={(e) => handleLoadComponent(dispatch, e, "global_maps")}>
                <IoMapOutline color={mapsIconColor} size={25} />
            </div>
            <div style={mapsDivStyle} onClick={(e) => handleLoadComponent(dispatch, e, "global_maps")}>
                Maps
            </div>
            <div style={{ paddingTop: "20px", cursor: "pointer" }} onClick={(e) => handleLoadComponent(dispatch, e, "global_work")}>
                <LuKanban color={workIconColor} size={25} />
            </div>
            <div style={workDivStyle} onClick={(e) => handleLoadComponent(dispatch, e, "global_work")}>
                Work
            </div>
            <div style={{ paddingTop: "20px", cursor: "pointer" }} onClick={(e) => handleLoadComponent(dispatch, e, "global_action")}>
                <BsBell color={actionIconColor} size={25} />
            </div>
            <div style={actionDivStyle} onClick={(e) => handleLoadComponent(dispatch, e, "global_action")}>
                Action
            </div>
        </div>
    );
}

export default RoadmapNav;