import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../Core/hooks';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';

function Freelancer() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navigation />
            <div className="pageHeader">
                <div className="standardPublicPage60">
                    <h1 className="homePageHeader">
                        Empower Freelance Collaboratives to Work Seamlessly
                    </h1>
                </div>
            </div>
            <div className="standardPublicPage60">
                <div className="standardPublicParagraph">
                    Freelancers thrive on flexibility, but when working together on collaborative projects, 
                    it's easy for communication to break down, tasks to overlap, and deadlines to slip. 
                    Traditional tools often assume rigid hierarchies, but as a freelance collective, 
                    you need something more adaptable.  
                </div>
                <div className="standardPublicParagraph">
                    Our platform is designed to help <b>freelance collaboratives coordinate effortlessly
                    </b> - without the need for hierarchies. Whether you're working on a creative campaign, a product 
                    launch, or a large - scale client project, we make it easy to stay <b>organized, aligned, 
                    and </b>.
                </div>
                <h2 className="standardPublicH2">Effortless Coordination</h2>
                <div className="standardPublicParagraph">
                    Freelance projects often involve diverse skills and contributors, which can lead to 
                    miscommunication and wasted effort. Our platform centralizes everything, allowing 
                    collaborators to assign tasks, set priorities, and share updates in real time. 
                    No manager required—just a clear, streamlined process that everyone can follow.  
                </div>
                <h2 className="standardPublicH2">Simplify Task Management</h2>
                <div className="standardPublicParagraph"> 
                    With everyone juggling multiple clients and projects, task clarity is essential. 
                    Our tool helps each team member see exactly what needs to be done, by whom, and 
                    when. Organize work into clear steps, avoid overlap, and ensure deadlines are 
                    met without unnecessary meetings or confusion.  
                </div>
                <h2 className="standardPublicH2">Foster Alignment Without Hierarchies</h2>
                <div className="standardPublicParagraph">  
                    Freelance collaboratives work best when every voice is valued and every role is clear. 
                    Our platform provides a transparent view of project progress, ensuring that everyone is 
                    on the same page. Keep communication flowing smoothly and ensure no details fall through 
                    the cracks.  
                </div>
                <h2 className="standardPublicH2">Stay Flexible and Agile</h2>
                <div className="standardPublicParagraph">  
                    Freelancers are no strangers to changing client demands or last-minute pivots. 
                    Our platform adapts to your workflow, letting you update plans on the fly without 
                    losing track of progress. Move at the pace of your project without the rigidity of 
                    traditional tools.  
                </div>
                <h2 className="standardPublicH2">Streamline Collaboration Across Projects</h2>
                <div className="standardPublicParagraph">  
                    Whether you're brainstorming ideas, refining deliverables, or reviewing final outputs, 
                    our platform keeps everything in one place. Share files, exchange feedback, and 
                    track updates - all within a single, user-friendly space.  
                </div>
                <h2 className="standardPublicH2">Designed for Freelance Teams</h2>
                <div className="standardPublicParagraph">  
                    We understand that freelancers value independence and flexibility. That's why our 
                    platform prioritizes ease of use and adaptability. It's a tool that works the way 
                    you do, giving you the structure you need without compromising your freedom.  
                </div>
                <div className="standardPublicParagraph">
                    By removing the need for hierarchies and simplifying coordination, we empower freelance 
                    teams to deliver their best work together. Keep your collective organized, aligned, and 
                    focused on results—no matter how complex the project.  
                </div>
 


                <div className="standardPublicParagraph">
                    Ready to simplify collaboration for your freelance team?&nbsp;
                    <Link to="" onClick={(e) => { e.preventDefault(); navigate("/") }}>
                        Start your free trial today!
                    </Link>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Freelancer;