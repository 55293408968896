import React from 'react';

function SubNav() {
    return (
        <div style={{ width: "100%", padding: "1px", fontSize: "11pt", 
            backgroundColor: "#F8F9FA", margin: "0px 10px" }}>
            {/* Contextual sub menu based on Nav selection */}
        </div>
    );
}

export default SubNav;