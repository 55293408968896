import React from 'react';

function Main() {
    return (
        <div style={{ height: "90vh", width: "100%", backgroundColor: "#fff", borderBottomRightRadius: "20px",
            padding: "10px 15px"
         }}>
            
        </div>
    );
}

export default Main;