import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../Core/hooks';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';

function SoftwareDevelopmentTeam() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navigation />
            <div className="pageHeader">
                <div className="standardPublicPage60">
                    <h1 className="homePageHeader">Empower Small Software Development Teams to Build Faster</h1>
                </div>
            </div>
            <div className="standardPublicPage60">
                <div className="standardPublicParagraph">
                    Small software development teams often face unique challenges: balancing tight deadlines, 
                    managing lean resources, and maintaining efficiency without getting bogged down by 
                    unnecessary complexity. Traditional project management platforms can feel overwhelming, 
                    with bloated features that slow your team down instead of speeding it up.  
                </div>
                <div className="standardPublicParagraph">
                    Our platform is purpose-built to help small development teams <b>support fast 
                    development without the complexity.</b> Whether you're building a plugin, developing 
                    an app, or working on your next big software project, we provide the tools to keep 
                    your team <b>organized and efficient</b>.
                </div>
                <h2 className="standardPublicH2">Streamlined Project Organization</h2>
                <div className="standardPublicParagraph"> 
                    Manage your entire development process in one intuitive platform. Create tasks, 
                    set priorities, and organize sprints without getting lost in excessive menus or 
                    confusing workflows. Focus on coding, not managing your management tool.  
                </div>
                <h2 className="standardPublicH2">Stay Agile Without the Overhead</h2>
                <div className="standardPublicParagraph">
                    Your team thrives on flexibility, and our platform keeps pace with your agile workflow. 
                    Whether you follow Scrum, Kanban, or a hybrid approach, our tools adapt to your process, 
                    helping you respond to changes quickly and effectively.  
                </div>
                <h2 className="standardPublicH2">Focus on What Matters</h2>
                <div className="standardPublicParagraph">  
                    Large platforms are often packed with features you don't need. We strip away the noise, 
                    giving you just what you need to stay productive—nothing more, nothing less. Track 
                    progress, meet deadlines, and deliver results without getting bogged down.  
                </div>
                <h2 className="standardPublicH2">Keep Communication Clear and Centralized</h2>
                <div className="standardPublicParagraph">  
                    Avoid the chaos of fragmented communication across email, chat, and other platforms. 
                    Our tool keeps everything in one place, allowing your team to share updates, exchange 
                    ideas, and review progress effortlessly.  
                </div>
                <h2 className="standardPublicH2">Manage Development at Any Scale</h2>
                <div className="standardPublicParagraph"> 
                    Whether you're building a simple plugin or tackling a multi-layered software project, 
                    our platform scales with your needs. Manage tasks across team members, track dependencies, 
                    and ensure nothing falls through the cracks.  
                </div>
                <h2 className="standardPublicH2">Enhance Efficiency</h2>
                <div className="standardPublicParagraph">  
                    Development teams often face competing priorities and limited resources. With clear task 
                    assignments and real-time updates, our platform ensures everyone knows what to work on 
                    and when, keeping your team aligned and productive.  
                </div>
                <h2 className="standardPublicH2">Why Choose Us?</h2>
                <ul className="standardPublicParagraph"> 
                    <li><b>Lightweight and Effective:</b> Get just the features you need without overloading your team.</li>
                    <li><b>Easy to Use:</b> Start managing your projects in minutes with an intuitive interface.</li>
                    <li><b>Built for Speed:</b> Designed for small teams who need to move fast and stay efficient.</li>
                </ul> 
                <div className="standardPublicParagraph">
                    By removing unnecessary complexity, our platform lets you focus on what your team does 
                    best: creating amazing software. Stay organized, meet your deadlines, and deliver exceptional 
                    results with ease.  
                </div>
                <div className="standardPublicParagraph">
                    Ready to simplify your workflow and accelerate your development process?&nbsp;
                    <Link to="" onClick={(e) => { e.preventDefault(); navigate("/") }}>
                        Start your free trial today!
                    </Link>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default SoftwareDevelopmentTeam;