import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../Core/hooks';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';

function Team() {

    const dispatch = useAppDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navigation />
            <div className="pt-4" style={{ width: "80%", margin: "0px auto", paddingBottom: "100px" }}>
                <h1 className="text-center" style={{ fontSize: "32pt", marginTop: "75px", marginBottom: "50px" }}>
                    We're building a tool that lets teams thrive and managers lead
                </h1>
                <div style={{ marginTop: "20px", marginBottom: "50px", fontSize: "16pt" }}>
                    Our mission is to empower teams by eliminating the burden of manual data entry, 
                    enabling seamless collaboration, and providing managers with real-time insights to 
                    drive productivity and deliver results with confidence.
                </div>
                <div style={{ width: "100%", margin: "auto 0px", textAlign: "center" }}>
                    <img src='images/adam-white.jpg' alt='Founder, Adam VanBuskirk' style={{ width: "50%" }} />
                </div>
                <div style={{ width: "100%", margin: "auto 0px", textAlign: "center", paddingTop: "50px" }}>
                    <h2>Adam VanBuskirk, Founder</h2>
                    <a href="https://www.linkedin.com/in/adam-vanbuskirk-28b93136/">
                        I'm a coder, you a marketer? Let's talk
                    </a>    
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Team;