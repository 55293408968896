import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Core/store';
//import { Credentials } from '../Models/Requests/Credentials';
import { axiosPublic, axiosPrivate } from '../Core/axios';
//import { Project } from '../Models/Project';
//import { List } from '../Models/List';
import { sliceStatus } from '../Helpers/types';
import { EmailSupportParam } from '../Models/Requests/EmailSupportParam';
import { JoinWaitlistParam } from '../Models/Requests/JoinWaitlistParam';
//import { decodeToken } from '../Helpers/DecodeToken';
//import { NewListParams } from '../Models/Requests/NewListParams';
//import { Settings } from 'http2';
//import { userSliceStatus } from '../Helpers/types';
//import { PasswordReset } from '../Models/Requests/PasswordReset';

export interface UtilityState {
  //marketingSource: string;
  status: sliceStatus;
  errorMessage: string;
}

const initialState: UtilityState = {
  //marketingSource: "",
  status: "unset",
  errorMessage: "",
};

export const emailSupport = createAsyncThunk(
  'api/utility/email/support',
  async (param: EmailSupportParam) => {    
    return await axiosPublic.post('api/utility/email/support',
      JSON.stringify(param),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const joinWaitlist = createAsyncThunk(
  'api/utility/join/waitlist',
  async (param: JoinWaitlistParam) => {    
    return await axiosPublic.post('api/utility/join/waitlist',
      JSON.stringify(param),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const UtilitySlice = createSlice({
  name: 'utility',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(emailSupport.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(emailSupport.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(joinWaitlist.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(joinWaitlist.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
  },
});

export const getUtility = (state: RootState) => state.utility;
export default UtilitySlice.reducer;
