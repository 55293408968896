import React, { CSSProperties } from 'react';
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { getRoadmap } from '../Store/Roadmap';
import { loadComponent } from '../Store/Settings';
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { BsBell } from "react-icons/bs";
import { LuKanban } from "react-icons/lu";
import { BiSolidLeftArrow, BiSearch } from "react-icons/bi";
import { IoChatbubbleOutline, IoMapOutline, IoSettingsOutline, IoPeopleOutline } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import { VscTriangleDown } from "react-icons/vsc";
import { Roadmap } from '../Models/Roadmap';
import { loadedComponentType, loadedSubComponentType } from '../Helpers/types';
import { handleLoadComponent } from '../Helpers/handleLoadComponent';
import ChatSubNav from './ChatSubNav';
import { getSettings } from '../Store/Settings';

interface Props {
    roadmap: Roadmap | undefined;
}

function LeftSubNav(props: Props) {

    const dispatch = useAppDispatch();
    const settingsState = useAppSelector(getSettings);
    const roadmapState = useAppSelector(getRoadmap);

    let navItemStyle = { 
        fontSize: "10pt",
        display: "inline-block", 
        paddingLeft: "10px", 
        color: "#bec1c3" 
    };

    let navItemSelectedStyle = {
        fontSize: "10pt",
        display: "inline-block",
        paddingLeft: "10px",
        color: "#e55c02", fontWeight: "450",
        textDecoration: "underline", textUnderlineOffset: "5px", textDecorationThickness: "1px",
    };

    /* icon selected / not selected classes */

    let dashboardTabLoaded = (props.roadmap && props.roadmap.loaded && settingsState.settings.loadedComponentType === "dashboard");
    let dashboardIconColor = (dashboardTabLoaded) ? "#e55c02" : "#bec1c3";
    let dashboardDivStyle: CSSProperties = (dashboardTabLoaded) ? navItemSelectedStyle : navItemStyle;

    let roadmapTabLoaded = (props.roadmap && props.roadmap.loaded && settingsState.settings.loadedComponentType === "roadmap");
    let roadmapIconColor = (roadmapTabLoaded) ? "#e55c02" : "#bec1c3";
    let roadmapDivStyle: CSSProperties = (roadmapTabLoaded) ? navItemSelectedStyle : navItemStyle;

    let membersTabLoaded = (props.roadmap && props.roadmap.loaded && settingsState.settings.loadedComponentType === "members");
    let membersIconColor = (membersTabLoaded) ? "#e55c02" : "#bec1c3";
    let membersDivStyle: CSSProperties = (membersTabLoaded) ? navItemSelectedStyle : navItemStyle;

    let actionTabLoaded = (props.roadmap && props.roadmap.loaded && settingsState.settings.loadedComponentType === "action");
    let actionIconColor = (actionTabLoaded) ? "#e55c02" : "#bec1c3";
    let actionDivStyle: CSSProperties = (actionTabLoaded) ? navItemSelectedStyle : navItemStyle;

    return (
        <div style={{ height: "100vh",backgroundColor: "#27282d" }}>

            <div style={{ width: "100%", color: "#F8F9FA", paddingTop: "10px", paddingLeft: "20px",
                paddingRight: "20px", fontWeight: "500", textAlign: "center"
             }}>
                <div style={{ width: "5%", display: "inline-block", position: "relative", top: "-7px", cursor: "pointer" }} 
                    onClick={(e) => handleLoadComponent(dispatch, e, "global_maps")}>
                    <BiSolidLeftArrow size={12} color="#F8F9FA" />
                </div>
                <div style={{ display: "inline-block", paddingLeft: "10px", paddingRight: "10px", textOverflow: "ellipsis",
                    width: "calc(90%)", overflow: "hidden", whiteSpace: "nowrap" }}>
                    {(props.roadmap) ? props.roadmap.name : "Loading roadmap..."}&nbsp;&nbsp;
                </div>
                <div style={{ width: "5%", display: "inline-block", position: "relative", top: "-7px" }}>
                    <IoSettingsOutline size={16} color="#bec1c3" />
                </div>
                
                {/*
                <div style={{ width: "20%", display: "inline-block", textAlign: "right", paddingRight: "20px" }}>
                    <RiArrowDownSFill  size={24} color="#909495" />
                </div>
                */}
            </div>

            <div style={{ width: "80%", margin: "10px auto 0px auto", color: "#909495", cursor: "pointer" }}
                onClick={(e) => handleLoadComponent(dispatch, e, "dashboard")}>
                <div style={{ display: "inline-block", textAlign: "right" }}>
                    <RxDashboard size={18} color={dashboardIconColor} />
                </div>
                <div style={dashboardDivStyle}>
                    Dashboard
                </div>
            </div>

            <div style={{ width: "80%", margin: "10px auto 0px auto", color: "#909495", cursor: "pointer" }} 
                onClick={(e) => handleLoadComponent(dispatch, e, "roadmap")}>
                <div style={{ display: "inline-block", textAlign: "right" }}>
                    <IoMapOutline  size={18} color={roadmapIconColor} />
                </div>
                <div style={roadmapDivStyle}>
                    Roadmap
                </div>
            </div>

            <div style={{ width: "80%", margin: "10px auto 0px auto", color: "#909495", cursor: "pointer" }}
                onClick={(e) => handleLoadComponent(dispatch, e, "members")}>
                <div style={{ display: "inline-block", textAlign: "right" }}>
                    <IoPeopleOutline size={18} color={membersIconColor} />
                </div>
                <div style={membersDivStyle}>
                    Members
                </div>
            </div>

            <div style={{ width: "80%", margin: "10px auto 0px auto", color: "#909495", cursor: "pointer" }}
                onClick={(e) => handleLoadComponent(dispatch, e, "action")}>
                <div style={{ display: "inline-block", textAlign: "right" }}>
                    <BsBell size={18} color={actionIconColor} />
                </div>
                <div style={actionDivStyle}>
                    Action
                </div>
            </div>

            <hr style={{ width: "80%", color: "#909495", margin: "15px auto 0px auto" }} />
{/*
            <div style={{ width: "80%", margin: "10px auto 0px auto", color: "#909495" }}>
                <div style={{ width: "10%", display: "inline-block" }}>
                    <IoMapOutline size={20} color="#909495" />
                </div>
                <div style={{color: "#bec1c3", width: "50%",fontSize: "12pt", display: "inline-block", paddingLeft: "7px" }}>
                    Roadmaps
                </div>
                <div style={{ width: "40%", display: "inline-block", textAlign: "right" }}>
                    <div style={{ display: "inline-block", position: "relative", top: "1px", paddingRight: "10px" }}>
                        <BiSearch size={16} color="#909495" />
                    </div>
                    <div style={{ display: "inline-block", paddingRight: "10px" }}>
                        <AiOutlinePlus size={16} color="#909495" />
                    </div>
                    <div style={{ display: "inline-block", position: "relative", top: "1px" }}>
                        <VscTriangleDown  size={18} color="#909495" />
                    </div>
                </div>
            </div>
*/}
            <div style={{ width: "80%", margin: "10px auto 0px auto", color: "#909495" }}>
                <div style={{ width: "10%", display: "inline-block" }}>
                    <LuKanban size={20} color="#909495" />
                </div>
                <div style={{color: "#bec1c3", width: "50%",fontSize: "12pt", display: "inline-block", paddingLeft: "7px" }}>
                    Work
                </div>
                <div style={{ width: "40%", display: "inline-block", textAlign: "right" }}>
                    <div style={{ display: "inline-block", position: "relative", top: "1px", paddingRight: "10px" }}>
                        <BiSearch size={16} color="#909495" />
                    </div>
                    <div style={{ display: "inline-block", paddingRight: "10px" }}>
                        <AiOutlinePlus size={16} color="#909495" />
                    </div>
                    <div style={{ display: "inline-block", position: "relative", top: "1px" }}>
                        <VscTriangleDown  size={18} color="#909495" />
                    </div>
                </div>
            </div>
            <ChatSubNav />
        </div>
    );
}

export default LeftSubNav;