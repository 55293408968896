import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

//import Footer from './Components/Footer';
//import Login from './Pages/Public/Login';
import Home from './Pages/Public/Home';
//import MemberHome from './Pages/Private/MemberHome';
import Privacy from './Pages/Public/Privacy';
import Terms from './Pages/Public/Terms';
import Products from './Pages/Public/Products';
import WhyHerdr from './Pages/Public/WhyHerdr';
import Team from './Pages/Public/Team';
import Pricing from './Pages/Public/Pricing';
import TechStartupFounder from './Pages/Public/TechStartupFounder';
import CompanyExecutives from './Pages/Public/CompanyExecutives';
import ProductDesigner from './Pages/Public/ProductDesigner';
import CreativeAgency from './Pages/Public/CreativeAgency';
import Freelancer from './Pages/Public/Freelancer';
import BootstrappedEntrepreneur from './Pages/Public/BootstrappedEntrepreneur';
import NonProfit from './Pages/Public/NonProfit';
import AcademicResearchTeam from './Pages/Public/AcademicResearchTeam';
import SoftwareDevelopmentTeam from './Pages/Public/SoftwareDevelopmentTeam';
import ConsultantCoach from './Pages/Public/ConsultantCoach';
import EcommerceTeam from './Pages/Public/EcommerceTeam';
import ProjectInvite from './Pages/Public/ProjectInvite';
import ContainerInvite from './Pages/Public/ContainerInvite';
import NotFound404 from './Pages/Public/NotFound404';
import Login from './Pages/Public/Login';
import Register from './Pages/Public/Register';
import Forgot from './Pages/Public/Forgot';
import Recover from './Pages/Public/Recover';
import Dash from './Pages/Private/Dash';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css'
import { ToastContainer } from 'react-toastify';
import { refreshAccessToken, getUser, getAvatar } from './Store/Auth';
import { getRoadmap, getRoadmaps } from './Store/Roadmap';
/*
import { getProject, getProjects, getLists, getCards, getLabels, getLabelsToCards, 
  getInvitesToProjects, getProjectMembers, getComments , getContainers, 
  getContainerMembers, getRoadmaps, getRoadmapCards, getRoadmapLabels, getRoadmapLabelsToCards,

} from './Store/Project';
 */
import { getUserSettings, heartBeat } from './Store/Settings';
//import { getAnalytics } from './Store/Analytics';
import { useAppSelector, useAppDispatch } from './Core/hooks';
//import { Container } from './Models/Container';

interface ComponentProps {
  children ?: React.ReactNode;
}

type Props = ComponentProps;

function App(props: Props) {
  
  const dispatch = useAppDispatch();
  const userState = useAppSelector(getUser);
  const roadmapState = useAppSelector(getRoadmap);
  //const projectState = useAppSelector(getProject);

  useEffect (() => {
    dispatch(refreshAccessToken());
    /* hearbeat to stay logged in */
    window.setInterval(() => {
      dispatch(heartBeat());
    }, 60000);
  },[]);

  useEffect (() => {
    if (userState.accessToken !== "" && roadmapState.roadmaps.length === 0) {
      dispatch(getAvatar());
      dispatch(getRoadmaps());
      dispatch(getUserSettings());
      //dispatch(getContainers());
    }
  }, [userState.accessToken]);

  /*
  useEffect (() => {
    if (userState.accessToken !== "" && projectState.projects.length === 0) {
      dispatch(getUserSettings());
      dispatch(getContainers());
    }
  }, [userState.accessToken]);

  useEffect (() => {
    if (projectState.status === "containersFetched") {
      let container = projectState.containers.find(c => c.loaded);
      if (container) {
        dispatch(getRoadmaps(container!.id));
      }
      dispatch(getProjects());
    }
  }, [projectState.status]);

  useEffect (() => {
    if (projectState.status === "inviteAccepted") {
      dispatch(getProjects());
      dispatch(getContainers());
    }
  }, [projectState.status]);

  useEffect (() => {
    if (projectState.projects.length > 0) {
      let loadedProject = projectState.projects.find(p => p.loaded);
      if (loadedProject) {
        dispatch(getComments(loadedProject.id));
        dispatch(getProjectMembers(loadedProject.id));
        dispatch(getInvitesToProjects(loadedProject.id));
        dispatch(getLabelsToCards(loadedProject.id));
        dispatch(getLabels(loadedProject.id));
        dispatch(getCards(loadedProject.id));
        dispatch(getLists(loadedProject.id));
      }
    }
  }, [projectState.projects]);

  useEffect (() => {
    if (projectState.containers.length > 0) {
      let loadedContainer = projectState.containers.find(c => c.loaded);
      if (loadedContainer) {
        dispatch(getContainerMembers(loadedContainer.id));
        dispatch(getRoadmaps(loadedContainer.id));
      }
    }
  }, [projectState.containers]);

  useEffect (() => {
    if (projectState.roadmaps.length > 0) {
      let container = projectState.containers.find(c => c.loaded);
      if (container) {
        let roadmap = projectState.roadmaps.find(r => r.containerId === container!.id);
        if (roadmap) {          
          dispatch(getRoadmapLabels(roadmap.id));
          dispatch(getRoadmapLabelsToCards(roadmap.id));
        }
      }
    }
  }, [projectState.roadmaps]);

  useEffect (() => {
    if (projectState.lists.length > 0) {
      let loadedProject = projectState.projects.find(p => p.loaded);
      if (loadedProject) {
        dispatch(getLists(loadedProject.id));
      }
    }
  }, [projectState.projects]);
  */
  return (
      <Router>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
          />
        {/*<Navigation />*/}  
        <Routes>
          {/*
          <Route path="/">
            <Route path=":ac" element={<Home />} />
            <Route path="" element={<Home />} />
          </Route>
          */}
          <Route path="" element={<Home />} />
          <Route path="/login">
            <Route path=":ac" element={<Login />} />
            <Route path="" element={<Login />} />
          </Route>
          <Route path="/recover">
            <Route path=":ar" element={<Recover />} />
            <Route path="" element={<Recover />} />
          </Route>
          <Route path="/project/invite/">
            <Route path=":project/:link" element={<ProjectInvite />} />
          </Route>
          <Route path="/container/invite/">
            <Route path=":container/:link" element={<ContainerInvite />} />
          </Route>
          <Route path="/register" element={<Register />} />
          {/*<Route path='/members' element={<MemberHome />} /> */}
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/products' element={<Products />} />
          <Route path='/why-herdr' element={<WhyHerdr />} />
          <Route path='/team' element={<Team />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/tech-startup-founders' element={<TechStartupFounder />} />
          <Route path='/creative-agencies' element={<CreativeAgency />} />
          <Route path='/freelancers' element={<Freelancer />} />
          <Route path='/product-designers' element={<ProductDesigner />} />
          <Route path='/bootstrapped-entrepreneurs' element={<BootstrappedEntrepreneur />} />
          <Route path='/small-nonprofits' element={<NonProfit />} />
          <Route path='/academic-research-teams' element={<AcademicResearchTeam />} />
          <Route path='/software-development-teams' element={<SoftwareDevelopmentTeam />} />
          <Route path='/consultants-coaches' element={<ConsultantCoach />} />
          <Route path='/ecommerce-teams' element={<EcommerceTeam />} />
          <Route path='/company-executives' element={<CompanyExecutives />} />
          <Route path='/forgot' element={<Forgot />} />
          <Route path='/dash' element={<Dash />} />
          {/*
          <Route path='/' element={<Login/>} />       
          <Route path='/admin' element={<Admin/>} />
          <Route path='/inventory' element={<Inventory/>} />
          <Route path='/loads' element={<Loads/>} />
          <Route path='/load/:loadId/:scheduleDate' element={<Receipt />} />          
          <Route path='/process' element={<Process/>} />
          <Route path='/reports' element={<Reports/>} />
          */}
          <Route path='*' element={<NotFound404 />} />
        </Routes>
      </Router>
  );
}

export default App;
