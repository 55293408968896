import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../Core/hooks';
import { getUser, login, socialLogin, activateUser, resendActivationLink } from '../../Store/Auth';
import { isValidEmail } from '../../Helpers/isValidEmail';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import GoogleSSO from '../../Components/GoogleSso';
import { decodeToken } from '../../Helpers/DecodeToken';

function Login() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector(getUser);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [success, setSuccess] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [loginSubmitted, setLoginSubmitted] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [queryParams] = useSearchParams();
    //const [activationCode, setActivationCode] = useState<string>(null);  
    
    useEffect(() => {
        if (queryParams.get('ac')) {
            let code = queryParams.get('ac');
            if (code !== null) {
                dispatch(activateUser(code));
                setSuccess(true);
                setSuccessMessage("Your account has been activated. You can now login.")
                setTimeout(() => {
                    setSuccess(false);
                    setSuccessMessage("");
                }, 5000);
                //setActivationCode(code);
            }
        }
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (loginSubmitted) {
            if (user.accessToken !== "" && user.status !== "loading"){
                //navigate('/members/home');
                navigate('/dash');
            } else if (user.status === "failed") {
                if (user.errorMessage !== "Your account needs activated."){
                    setError(true);
                    showError(user.errorMessage);
                    setLoginSubmitted(false);
                }
            }
        }
    }, [user.status]);

    const showError = (error: string) => {
        setError(true);
        setErrorMessage(error);
        setLoginSubmitted(false);
        setTimeout(() => setError(false), 2000);
    }

    const toggleShowPassword = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    }

    const handleLogin = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
        
        e.preventDefault();

        if (e.type === "keydown" ) {
            let keypress = e as React.KeyboardEvent;
            if (keypress.key !== 'Enter') {
                return;
            }            
        }

        if (!isValidEmail(email) || password === "") {
            showError("Please enter all fields");
        } else {
            setLoginSubmitted(true);
            dispatch(login({
                email: email,
                password: password
            }));
        }
    }

    const resendActivation = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (email !== "") {
            dispatch(resendActivationLink(email));
            setSuccess(true);
            setSuccessMessage("Your activation code has been resent");
            setTimeout(() => {
                setSuccess(false);
                setSuccessMessage("");
            }, 3000);

        } else {
            showError("Enter your email so we can send the activation link");
        }
    }

    function processGoogleLogin(response: google.accounts.id.CredentialResponse){
        const payload = decodeToken(response.credential as string);
        if (payload) {
            setLoginSubmitted(true);
            dispatch(socialLogin({ 
                email: payload.email, 
                firstName: payload.given_name, 
                lastName: payload.family_name, 
                platform: "google",
                method: "login",
                token: response.credential,
                picture: payload.picture,
            }));
        } else {
            showError("Social login failed");
        }
    }

    return (
    <div style={{ display: "flex", alignItems: "top" }}>
        <div className="loginPageLeft">
            <div style={{ width: "100%" }}>
                <div className="mobileOnlyLogo">
                    <Link to="/" className="text-white fw-bold">
                        <div>
                            <img className="logoCat" src="/images/herdr-orange-logo-cat-v1.png" alt="herdr.io" />
                        </div>
                        <div style={{ position: 'relative', top: "-2px", left: '10px'}}>
                            <img className="pb-2 pt-2 logoText" src="/images/logo-black-text.png" alt="herdr.io" />
                        </div>
                    </Link>
                </div>
                <h1 style={{ fontSize: "24pt", textAlign: "center" }}>
                    Welcome back!
                </h1>
                <div style={{ width: "80%", margin: "30px auto 0px auto" }}>
                    <GoogleSSO buttonText="signin_with" loginHandler={processGoogleLogin} />
                    <div className="mt-2 text-center">
                        OR
                    </div>
                </div>
                <div style={{ width: "80%", margin: "16px auto 0px auto" }}>
                    {success &&
                        <div className="success" style={{ marginTop: "10px" }}>
                            {successMessage}
                        </div>
                    }
                    {error &&
                        <div className="error" style={{ marginTop: "10px" }}>
                            {errorMessage}
                        </div>
                    }
                    {user.errorMessage === "Your account needs activated." &&
                        <div className="error" style={{ marginTop: "10px" }}>
                            Your account needs activated. Click <Link to="" onClick={(e) => resendActivation(e)}>here</Link> to resend your activation code to your email
                        </div>                   
                    }

                    <input type="input" style={{ marginTop: "10px" }} className="form-control" placeholder="Email Address" 
                        value={email}
                        onChange={(e) => handleEmailChange(e)} />
                    {!showPassword ?
                        <input type="password" style={{ marginTop: "10px" }} className="form-control" placeholder="Password"
                            value={password}
                            onChange={(e) => handlePasswordChange(e)} />
                    :
                        <input type="input" style={{ marginTop: "10px" }} className="form-control" placeholder="Password"
                            value={password}
                            onChange={(e) => handlePasswordChange(e)} />
                    }    
                    <div onClick={(e) => toggleShowPassword(e)} style={{
                        float: "right", position: "relative", top: "-32px", left: "-15px",
                        textAlign: "right", width: "20px"
                    }}>
                        {!showPassword ?
                            <IoEyeOutline color="gray" size={18} />
                        :
                            <IoEyeOffOutline color="gray" size={18} />
                        }
                    </div>
                    <div style={{ marginTop: "10px", textAlign: "right" }}>
                        <Link to="/forgot" className="blackLink forgotPasswordLink" >
                            Forgot password?
                        </Link>
                    </div>
                    <button className="orangeButton" style={{ width: "100%", marginTop: "10px" }}
                        onClick={(e) => handleLogin(e)} onKeyDown={(e) => handleLogin(e)}>
                        Sign-in
                    </button>
                    <div className="loginPageSignup">
                        Don't have an account?&nbsp;
                        <Link to="/" className="blackLink">
                            Sign-up
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="loginPageRight">
            <div style={{ display: "inline-block", width: "100%", verticalAlign: "top" }}>
                <div style={{ color: "#fff", textAlign: "center", fontSize: "18pt" }}>
                    Empowering small teams to create big things
                </div>
                <div style={{ textAlign: "center", color: "#cacfd2", marginTop: "20px", fontSize: "14pt" }}>
                    Herdr empowers small teams to transform ideas into successful products. 
                    Go from brainstorming to roadmap to action in minutes.
                </div>
                <div style={{ color: "#cacfd2", marginTop: "20px", fontSize: "14pt", textAlign: "center" }}>
                    <strong>Sign-in or try us for free!</strong>
                </div>
                <div style={{ color: "#cacfd2", marginTop: "20px", fontSize: "14pt", textAlign: "center" }}>
                    <img src='/images/productivity-app.png' style={{ width: "100%" }} />
                </div>
            </div>
        </div>
    </div>
    );
}

export default Login;