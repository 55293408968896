import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../Core/hooks';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';

function WhyHerdr() {

    const dispatch = useAppDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navigation />
            <div className="pt-4" style={{ width: "80%", margin: "0px auto" }}>
                <h1 className="text-center">Why Herdr?</h1>
                <div style={{ marginTop: "20px", marginBottom: "50px", fontSize: "18pt", textAlign: "center" }}>
                    Why not? It's FREE.
                </div>
            </div>
            <Footer />
        </>
    );
}

export default WhyHerdr;