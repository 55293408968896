import React, { CSSProperties } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../Core/hooks';
import { getRoadmap } from '../../Store/Roadmap';
import { getSettings } from '../../Store/Settings';
//import Footer from '../../Components/Footer';
//import Navigation from '../../Components/Navigation';
import SubNav from '../../Components/SubNav';
import TopNav from '../../Components/TopNav';
import LeftNav from '../../Components/LeftNav';
import LeftSubNav from '../../Components/LeftSubNav';
import Main from '../../Components/Main';
import RoadmapSubNav from '../../Components/RoadmapSubNav';
import ActionSubNav from '../../Components/ActionSubNav';
import WorkSubNav from '../../Components/WorkSubNav';
import HerdrSubNav from '../../Components/HerdrSubNav';

function Dash() {

  const roadmapState = useAppSelector(getRoadmap);
  const settingsState = useAppSelector(getSettings);
  const roadmap = roadmapState.roadmaps.find(r => r.loaded);

  let mainStyle: CSSProperties = (settingsState.settings.loadedComponentType === "global_herdr") ? 
    { display: "inline-block", width: "70%" }
  :
    { display: "inline-block", width: "77%" }
  ;

  if (settingsState.settings.loadedComponentType === "") 
    return (
      <div style={{ width: "100%", margin: "0px auto", paddingTop: "100px", textAlign: "center" }}>
        <div>
            <img style={{ width: "125px"}} src="/images/herdr-orange-logo-cat-v1.png" alt="herdr.io" />
        </div>
        <div style={{ fontStyle: "italic", fontSize: "28pt" }}>Loading...</div>
      </div>
    );

  return (
      <div style={{ display: "flex", alignItems: "top", margin: "0px auto", 
          backgroundColor: "#F8F9FA"
        }}>
          <div style={{ display: "inline-block", width: ".5%" }}></div>
        <div style={{ display: "inline-block", width: "4%" }}>
          <LeftNav />
        </div>

        {settingsState.settings.loadedComponentType === "roadmap" &&
          <div style={{ display: "inline-block", width: "18%" }}>
            <LeftSubNav roadmap={roadmap} />
          </div>
        }
        {settingsState.settings.loadedComponentType === "global_maps" &&
          <div style={{ display: "inline-block", width: "18%" }}>
            <RoadmapSubNav />
          </div>
        }
        {settingsState.settings.loadedComponentType === "global_work" &&
          <div style={{ display: "inline-block", width: "18%" }}>
            <WorkSubNav />
          </div>
        }
        {settingsState.settings.loadedComponentType === "global_action" &&
          <div style={{ display: "inline-block", width: "18%" }}>
            <ActionSubNav />
          </div>
        }
        {settingsState.settings.loadedComponentType === "global_herdr" &&
          <div style={{ display: "inline-block", width: "25%" }}>
            <HerdrSubNav />
          </div>
        }

        <div style={mainStyle}>
          <TopNav />
          <SubNav />
          <Main />
        </div>
        <div style={{ display: "inline-block", width: ".5%" }}></div>
      </div>
  );
}

export default Dash;