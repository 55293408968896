import React, { CSSProperties } from 'react';
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { getRoadmap } from '../Store/Roadmap';
import { loadComponent, toggleChat, getSettings } from '../Store/Settings';
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { BsBell } from "react-icons/bs";
import { LuKanban } from "react-icons/lu";
import { BiSolidLeftArrow, BiSearch } from "react-icons/bi";
import { IoChatbubbleOutline, IoMapOutline, IoSettingsOutline, IoPeopleOutline } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import { Roadmap } from '../Models/Roadmap';
import { loadedComponentType, loadedSubComponentType } from '../Helpers/types';
import { handleLoadComponent } from '../Helpers/handleLoadComponent';

function ChatSubNav() {

    const dispatch = useAppDispatch();
    const settingsState = useAppSelector(getSettings);

    const handleToggleChat = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        dispatch(toggleChat(!settingsState.settings.chatExpanded));
    }

    return (
        <>
            <hr style={{ width: "80%", color: "#909495", margin: "15px auto 0px auto" }} />

            <div style={{ width: "80%", margin: "10px auto 0px auto", color: "#909495" }}>
                <div style={{ width: "10%", display: "inline-block", position: "relative", top: "-2px" }}>
                    <IoChatbubbleOutline size={18} color="#909495" />
                </div>
                <div style={{color: "#bec1c3", width: "50%",fontSize: "12pt", display: "inline-block", paddingLeft: "7px" }}>
                    Chat
                </div>
                <div style={{ width: "40%", display: "inline-block", textAlign: "right" }}>
                    <div style={{ display: "inline-block", position: "relative", top: "1px", paddingRight: "10px" }}>
                        <BiSearch size={16} color="#909495" />
                    </div>
                    <div style={{ display: "inline-block", paddingRight: "10px" }}>
                        <AiOutlinePlus size={16} color="#909495" />
                    </div>
                    <div style={{ display: "inline-block", position: "relative", top: "1px", cursor: "pointer" }}
                        onClick={(e) => handleToggleChat(e)}>
                        {settingsState.settings.chatExpanded ? 
                            <VscTriangleUp  size={18} color="#909495" />
                        :
                            <VscTriangleDown  size={18} color="#909495" />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChatSubNav;