import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../Core/hooks';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';

function EcommerceTeam() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navigation />
            <div className="pageHeader">
                <div className="standardPublicPage60">
                    <h1 className="homePageHeader">
                        Simplify Success for Your E-Commerce Team
                    </h1>
                </div>
            </div>
            <div className="standardPublicPage60">
                <div className="standardPublicParagraph">
                    Running a small e-commerce business requires juggling a lot—product launches, 
                    marketing campaigns, inventory, logistics, and more. With so much on your plate, 
                    staying organized is key to driving sales and delivering great customer experiences. 
                    But complex tools or disorganized workflows can slow your team down and hold you back.  
                </div>
                <div className="standardPublicParagraph">
                    Our platform is here to help small e-commerce teams <b>manage product launches and marketing 
                    campaigns</b> with ease. Keep your team organized and efficient, so you can focus on scaling 
                    your business and reaching your goals.    
                </div>
                <h2 className="standardPublicH2">Streamline Product Launches</h2>
                <div className="standardPublicParagraph"> 
                    Launching a new product is a big undertaking, with dozens of moving parts. From 
                    initial planning to final rollout, our platform helps you organize every 
                    detail. Assign tasks, set deadlines, and track progress to ensure your 
                    launch goes off without a hitch.  
                <h2 className="standardPublicH2">Plan and Execute Marketing Campaigns</h2>
                <div className="standardPublicParagraph"> 
                    Drive traffic and boost sales with marketing campaigns that hit the mark. Use our 
                    platform to coordinate across your team, plan timelines, and manage deliverables 
                    for email campaigns, social ads, influencer outreach, and more—all in one place.  
                </div>
                <h2 className="standardPublicH2">Track Progress in Real Time</h2>
                <div className="standardPublicParagraph"> 
                    Stay on top of your team's efforts with clear, real-time updates. Monitor task completion, 
                    identify bottlenecks, and make adjustments quickly to keep your projects on track.  
                </div>
                <h2 className="standardPublicH2">Centralize Team Collaboration</h2>
                <div className="standardPublicParagraph"> 
                    E-commerce success relies on teamwork, from product development to fulfillment. 
                    With our platform, you can bring everyone together, share updates, provide feedback, 
                    and ensure alignment—all without sifting through endless email threads or scattered messages.  
                </div>
                <h2 className="standardPublicH2">Stay Organized Across Projects</h2>
                <div className="standardPublicParagraph">  
                    Whether you're launching a new collection, running a flash sale, or planning for 
                    seasonal promotions, our platform helps you manage multiple projects effortlessly. 
                    Keep track of deadlines, budgets, and goals without losing sight of the big picture.  
                </div>
                <h2 className="standardPublicH2">Boost Team Efficiency</h2>
                <div className="standardPublicParagraph">  
                    When your team knows what to work on and when, they can move faster and achieve more. 
                    Our platform simplifies workflows, so your team can focus on driving results instead of 
                    wrestling with disorganization or complicated tools.  
                </div>
                <h2 className="standardPublicH2">Why Choose Us?</h2>
                <ul className="standardPublicParagraph">
                    <li><b>User-Friendly:</b> Get your team up and running quickly with an intuitive interface.</li> 
                    <li><b>Flexible:</b> Adapt our tools to your team's unique processes and priorities.</li>
                    <li><b>Scalable:</b> Perfect for growing e-commerce teams that need to do more with less.</li> 
                </ul>
                <div className="standardPublicParagraph"></div>
                    By helping you stay organized and efficient, our platform empowers your e-commerce 
                    team to focus on what matters: <b>delivering great products and driving sales</b>. 
                    Simplify your operations, streamline collaboration, and achieve your business goals faster.  
                </div>
                <div className="standardPublicParagraph">
                    Ready to take your e-commerce team to the next level?&nbsp;
                    <Link to="" onClick={(e) => { e.preventDefault(); navigate("/") }}>
                        Start your free trial today and see the impact!
                    </Link>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default EcommerceTeam;