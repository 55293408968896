import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Core/store';
import { axiosPublic, axiosPrivate } from '../Core/axios';
import { Roadmap } from '../Models/Roadmap';
import { requestStatus } from '../Helpers/types';

export interface RoadmapState {
  roadmaps: Array<Roadmap>;
  status: requestStatus;
  errorMessage: string;
}

const initialState: RoadmapState = {
  roadmaps: [],
  status: "unset",
  errorMessage: ""
};

export const getRoadmaps = createAsyncThunk(
  'get-roadmaps',
  async () => {    
    return await axiosPrivate.get('api/roadmap',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const setRoadmaps = createAsyncThunk(
  'set/roadmaps',
  async (roadmaps: Array<Roadmap>) => {    
      return roadmaps;
});

export const RoadmapSlice = createSlice({
  name: 'roadmap',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoadmaps.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getRoadmaps.fulfilled, (state, action) => {
        state.status = 'idle';
        state.roadmaps = [...action.payload];
      })
      .addCase(getRoadmaps.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
  },
});

export const getRoadmap = (state: RootState) => state.roadmap;
export default RoadmapSlice.reducer;
