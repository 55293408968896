import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Core/store';
//import { Credentials } from '../Models/Requests/Credentials';
import { axiosPublic, axiosPrivate } from '../Core/axios';
//import { Project } from '../Models/Project';
//import { List } from '../Models/List';
import { sliceStatus } from '../Helpers/types';
//import { decodeToken } from '../Helpers/DecodeToken';
//import { NewListParams } from '../Models/Requests/NewListParams';
//import { Settings } from 'http2';
import { Settings } from '../Models/Settings';
import { loadedComponentType, loadedSubComponentType } from '../Helpers/types';
import { loadedComponentRequest } from '../Models/Requests/LoadComponentRequest';
//import { userSliceStatus } from '../Helpers/types';
//import { PasswordReset } from '../Models/Requests/PasswordReset';

export interface SettingsState {
  //showProjects: boolean;
  status: sliceStatus;
  errorMessage: string;
  settings: Settings;
  //avatar: string;
  //showingHelp: boolean;
  //defaultAvatarColor: string;
  //defaultAvatarFontColor: string;
  //userId: number;
  //timeZone: string;
  //herdrExpanded: boolean;
  //roadmapsExpanded: boolean;
  //workExpanded: boolean;
  //actionExpanded: boolean;
  //chatExpanded: boolean;
}

const initialState: SettingsState = {
  //showProjects: false,
  status: "unset",
  errorMessage: "",
  settings: {
    userId: 0,
    herdrExpanded: false,
    roadmapsExpanded: false,
    workExpanded: false,
    actionExpanded: false,
    chatExpanded: false,
    created: new Date(),
    modified: new Date(),
    loadedComponentType: "",
    loadedComponentSubtype: ""
  }
  //avatar: "",
  //showingHelp: false,
  //defaultAvatarColor: "",
  //defaultAvatarFontColor: "",
  //userId: 0,
  //timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  //herdrExpanded: false,
  //roadmapsExpanded: false,
  //workExpanded: false,
  //actionExpanded: false,
  //chatExpanded: false
};
/*
export const loadComponent = createAsyncThunk(
  'load/component',
  async (component: loadedComponentRequest) => {    
      return component;
});
*/
export const loadComponent = createAsyncThunk(
  'api/settings/component',
  async (component: loadedComponentRequest) => {    
    return await axiosPrivate.put('api/settings/component',
      JSON.stringify(component),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});
export const toggleHerdr = createAsyncThunk(
  'api/settings/toggle/herdr',
  async (show: boolean) => {    
    return await axiosPrivate.put('api/settings/toggle/herdr',
      JSON.stringify({
        show: show,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});
export const toggleRoadmaps = createAsyncThunk(
  'api/settings/toggle/roadmaps',
  async (show: boolean) => {    
    return await axiosPrivate.put('api/settings/toggle/roadmaps',
      JSON.stringify({
        show: show,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});
export const toggleWork = createAsyncThunk(
  'api/settings/toggle/work',
  async (show: boolean) => {    
    return await axiosPrivate.put('api/settings/toggle/work',
      JSON.stringify({
        show: show,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});
export const toggleAction = createAsyncThunk(
  'api/settings/toggle/action',
  async (show: boolean) => {    
    return await axiosPrivate.put('api/settings/toggle/action',
      JSON.stringify({
        show: show,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});
export const toggleChat = createAsyncThunk(
  'api/settings/toggle/chat',
  async (show: boolean) => {    
    return await axiosPrivate.put('api/settings/toggle/chat',
      JSON.stringify({
        show: show,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});
/*
export const saveSidebarSetting = createAsyncThunk(
  'api/settings/save/sidebar',
  async (show: boolean) => {    
    return await axiosPrivate.put('api/settings',
      JSON.stringify({
        show: show,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        //setShowSidebar(res.data.data);
        return res.data.showProjects;
      }
    );
});
*/
export const getUserSettings = createAsyncThunk(
  'api/settings/get/user/settings',
  async () => {    
    return await axiosPrivate.get('api/settings',
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const heartBeat = createAsyncThunk(
  'api/settings/heartbeat',
  async () => {    
    return await axiosPrivate.get('api/settings/heartbeat',
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});
/*
export const setShowHelp = createAsyncThunk(
'setShowHelp',
  async (show: boolean) => {    
      return show;
});
*/
export const SettingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    /*
      .addCase(showSidebar.pending, (state) => {
        state.status = 'loading';
      })*/
      .addCase(loadComponent.fulfilled, (state, action) => {
        state.status = 'idle';
        let settings: Settings = {...state.settings};
        settings.loadedComponentType = action.payload.loadedComponentType;
        settings.loadedComponentSubtype = action.payload.loadedComponentSubtype;
        state.settings = settings;
      })
      .addCase(toggleHerdr.fulfilled, (state, action) => {
        state.status = 'idle';
        let settings: Settings = {...state.settings};
        settings.herdrExpanded = action.payload;;
        state.settings = settings;
      })
      .addCase(toggleRoadmaps.fulfilled, (state, action) => {
        state.status = 'idle';
        let settings: Settings = {...state.settings};
        settings.roadmapsExpanded = action.payload;;
        state.settings = settings;
      })
      .addCase(toggleWork.fulfilled, (state, action) => {
        state.status = 'idle';
        let settings: Settings = {...state.settings};
        settings.workExpanded = action.payload;;
        state.settings = settings;
      })
      .addCase(toggleAction.fulfilled, (state, action) => {
        state.status = 'idle';
        let settings: Settings = {...state.settings};
        settings.actionExpanded = action.payload;;
        state.settings = settings;
      })
      .addCase(toggleChat.fulfilled, (state, action) => {
        state.status = 'idle';
        let settings: Settings = {...state.settings};
        settings.chatExpanded = action.payload;;
        state.settings = settings;
      })
      /*
      .addCase(saveSidebarSetting.fulfilled, (state, action) => {
        state.status = 'idle';
        state.showProjects = action.payload;
      })
      .addCase(saveSidebarSetting.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
        */
      .addCase(getUserSettings.fulfilled, (state, action) => {
        state.status = 'idle';
        //let settings: SettingsState = action.payload;
        state.settings = action.payload;
      })
      .addCase(getUserSettings.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      /*
      .addCase(setShowHelp.fulfilled, (state, action) => {
        state.status = 'idle';
        state.showingHelp = action.payload;
      })
        */
  },
});

export const getSettings = (state: RootState) => state.settings;
export default SettingsSlice.reducer;
