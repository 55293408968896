import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../Core/hooks';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';

function ProductDesigner() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navigation />
            <div className="pageHeader">
                <div className="standardPublicPage60">
                    <h1 className="homePageHeader">
                        Streamline Your Design Process: From Ideation to Delivery
                    </h1> 
                </div>
            </div>
            <div className="standardPublicPage60">
                <div className="standardPublicParagraph">
                    For product designers and UX teams, the journey from an idea to a finished product is 
                    complex. Managing brainstorming sessions, prototyping, feedback loops, and final 
                    delivery can feel overwhelming, especially when deadlines are tight, and multiple 
                    stakeholders are involved.  
                </div>
                <div className="standardPublicParagraph">
                    Our platform is here to simplify your process, helping you <b>streamline design from 
                    ideation to delivery.</b> Stay organized and keep your team aligned, whether you're 
                    creating wireframes, refining interfaces, or delivering your final design to developers.  
                </div>
                <h2 className="standardPublicH2">Efficient Idea Organization</h2>
                <div className="standardPublicParagraph">
                    Great designs start with great ideas. Capture concepts, sketches, and inspirations in a central 
                    location where your team can build on each other's creativity. Prioritize ideas and transform 
                    them into actionable plans with ease.  
                </div>
                <h2 className="standardPublicH2">Seamless Collaboration Across Teams</h2> 
                <div className="standardPublicParagraph">
                    Design is rarely a solo effort. Collaborate effortlessly with team members, developers, and 
                    stakeholders by keeping all your design assets, feedback, and tasks in one place. Share updates, 
                    iterate faster, and eliminate silos, so your entire team moves in sync.  
                </div>
                <h2 className="standardPublicH2">Keep Projects on Track</h2>
                <div className="standardPublicParagraph">
                    From sprints to long-term goals, our platform helps you stay on schedule. Break down your design 
                    process into clear milestones, assign tasks, and track progress at every stage. Meet your 
                    deadlines without the stress of juggling multiple tools or endless check-ins.  
                </div>
                <h2 className="standardPublicH2">Adaptable for Every Design Stage</h2>  
                <div className="standardPublicParagraph">
                    Whether you're brainstorming ideas, prototyping a mobile app, or refining a user interface, 
                    our tool adapts to your workflow. Easily manage different phases of your design process while 
                    keeping the big picture in focus.  
                </div>
                <h2 className="standardPublicH2">Centralized Feedback and Iteration</h2> 
                <div className="standardPublicParagraph">
                    Feedback is essential for great design, but managing it can be a headache. With our platform, 
                    you can collect, organize, and act on feedback in one centralized location. Keep the team 
                    aligned on revisions and ensure everyone's input is accounted for.  
                </div>
                <h2 className="standardPublicH2">Alignment From Concept to Delivery</h2>
                <div className="standardPublicParagraph">
                    Design projects often involve cross-functional teams, and miscommunication can lead 
                    to costly delays. Our platform ensures your designers, developers, and stakeholders 
                    stay aligned from concept to delivery. Reduce friction and focus on building great 
                    products.  
                </div>
                <h2 className="standardPublicH2">Why Choose Us?</h2>
                <ul className="standardPublicParagraph">
                    <li><b>Intuitive and Flexible:</b> Tailored for creative workflows, our tool adapts to your needs, not the other way around. </li> 
                    <li><b>Time-Saving:</b> Streamline every step of the design process, so you can focus on creating rather than managing. </li> 
                    <li><b>Collaboration-Friendly:</b> Ideal for teams working across time zones or departments, keeping everyone connected and aligned.</li>
                </ul>
                <div className="standardPublicParagraph">
                    By organizing and simplifying your design process, we enable you to focus on what 
                    you do best: creating exceptional user experiences. From initial sketches to final 
                    deliverables, our platform keeps you and your team moving forward - together.  
                </div>
                <div className="standardPublicParagraph">
                    Ready to take the complexity out of design?&nbsp;
                    <Link to="" onClick={(e) => { e.preventDefault(); navigate("/") }}>
                        Sign up for your free trial today!
                    </Link>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ProductDesigner;