import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../Core/hooks';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';

function CreativeAgency() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navigation />
            <div className="pageHeader">
                <div className="standardPublicPage60">
                    <h1 className="homePageHeader">
                        Bridge Vision and Execution for Creative Agencies
                    </h1>
                </div>
            </div>
            <div className="standardPublicPage60">

                <div className="standardPublicParagraph">
                    In a fast-paced creative agency, balancing visionary ideas with daily operations 
                    can be challenging. How do you ensure your team stays aligned, efficient, and 
                    focused when juggling multiple campaigns, client expectations, and tight deadlines?  
                </div>
                <div className="standardPublicParagraph">
                    Our platform is designed to help creative agencies like yours <b>connect big-picture goals 
                    with daily tasks</b> seamlessly. Free yourself from the chaos of project management, and 
                    focus on what you do best: delivering outstanding results.  
                </div>
                <h2 className="standardPublicH2">Align Your Team Around Shared Goals</h2>
                <div className="standardPublicParagraph">
                    From brand strategy to campaign execution, your team needs a clear direction to deliver 
                    cohesive results. Our tool provides a centralized hub where big-picture goals are broken 
                    down into actionable steps. Ensure every team member knows their role and how their tasks 
                    contribute to the broader vision.  
                </div>
                <h2 className="standardPublicH2">Effortless Task Management</h2>
                <div className="standardPublicParagraph">
                Creative agencies thrive on collaboration, but mismanaged workflows can lead to bottlenecks 
                and missed deadlines. With our platform, you can assign tasks, set priorities, and monitor 
                progress with ease. Keep your projects on track without endless back-and-forths or micromanagement.  
                </div>
                <h2 className="standardPublicH2">Streamline Collaboration</h2>
                <div className="standardPublicParagraph">
                    Ideas flow faster when your team collaborates effectively. Whether brainstorming concepts, 
                    refining designs, or drafting campaigns, our platform fosters real-time communication and 
                    transparency. Share updates, provide feedback, and eliminate silos, so everyone moves in sync.  
                </div>
                <h2 className="standardPublicH2">Simplify Complex Workflows</h2> 
                <div className="standardPublicParagraph">
                    Managing multiple clients and campaigns can get overwhelming, but it doesn't have to. Our tool 
                    simplifies complex workflows, letting you oversee all projects from one intuitive dashboard. 
                    Whether you're tracking deliverables or monitoring resource allocation, everything is streamlined 
                    to save you time and effort.  
                </div>
                <h2 className="standardPublicH2">Focus on Results, Not Chaos</h2>
                <div className="standardPublicParagraph">
                    When your processes run smoothly, your team can focus on delivering their creative best. 
                    Eliminate distractions, minimize confusion, and empower your agency to produce high-quality 
                    work that exceeds client expectations.  
                </div>
                <h2 className="standardPublicH2">Designed for Creative Minds</h2>
                <div className="standardPublicParagraph">
                    We understand that creativity doesn't thrive in overly rigid systems. That's why our platform 
                    is flexible, user-friendly, and tailored to the dynamic needs of creative agencies. Adapt it 
                    to your workflow, and let it work the way you do.  
                </div>
                <div className="standardPublicParagraph">
                    By connecting big-picture goals with day-to-day tasks, our platform helps you create a 
                    <b>clear path from ideation to delivery</b>. Keep your team aligned and efficient, so you can 
                    focus on what truly matters: <b>turning your creative vision into measurable success</b>.  
                </div>
                <div className="standardPublicParagraph">
                    Start your journey to better alignment and efficiency - &nbsp;
                    <Link to="" onClick={(e) => { e.preventDefault(); navigate("/") }}>
                        sign up for a free trial today!
                    </Link>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default CreativeAgency;