import { useState } from 'react';
import { useAppDispatch } from '../Core/hooks'
import { joinWaitlist } from '../Store/Utility';
//import { Link } from 'react-router-dom';
import { CSSProperties } from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { isValidEmail } from '../Helpers/isValidEmail';

interface Props {
    mostPopularButtonBorder: string;   
    mostPopularButtonColor: string;
    mostPopularButtonBackgroundColor: string; 
}

function PricingTiers(props: Props) {

    const dispatch = useAppDispatch();
    let [starterEmail, setStarterEmail] = useState<string>("");
    let [essentialsEmail, setEssentialsEmail] = useState<string>("");
    let [teamsEmail, setTeamsEmail] = useState<string>("");
    const [starterError, setStarterError] = useState<boolean>(false);
    const [starterSuccess, setStarterSuccess] = useState<boolean>(false);
    const [essentialsError, setEssentialsError] = useState<boolean>(false);
    const [essentialsSuccess, setEssentialsSuccess] = useState<boolean>(false);
    const [teamsError, setTeamsError] = useState<boolean>(false);
    const [teamsSuccess, setTeamsSuccess] = useState<boolean>(false);

    let mostPopularStyle: CSSProperties = {
        width: "70%", 
        backgroundColor: props.mostPopularButtonBackgroundColor, 
        padding: "15px", 
        borderRadius: "30px", 
        border: props.mostPopularButtonBorder, 
        color: props.mostPopularButtonColor, 
        fontWeight: "400", 
        fontSize: "14pt", 
        textAlign: "center"
    }

    const handleEmailChangeStarter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStarterEmail(e.target.value);
    }
    const handleEmailChangeEssentials = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEssentialsEmail(e.target.value);
    }
    const handleEmailChangeTeams = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTeamsEmail(e.target.value);
    }
    const handleJoinStarterWaitlist = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
        
        e.preventDefault();

        if (e.type === "keydown" ) {
            let keypress = e as React.KeyboardEvent;
            if (keypress.key !== 'Enter') {
                return;
            }            
        }
        
        if (isValidEmail(starterEmail)) {
            setStarterSuccess(true);
            setTimeout(() => setStarterSuccess(false), 2000);
            addToWaitlist("starter", starterEmail);
        } else {
            setStarterError(true);
            setTimeout(() => setStarterError(false), 2000);
        }
    }
    const handleJoinEssentialsWaitlist = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
        
        e.preventDefault();

        if (e.type === "keydown" ) {
            let keypress = e as React.KeyboardEvent;
            if (keypress.key !== 'Enter') {
                return;
            }            
        }
        
        if (isValidEmail(essentialsEmail)) {
            setEssentialsSuccess(true);
            setTimeout(() => setEssentialsSuccess(false), 2000);
            addToWaitlist("essentials", essentialsEmail);
        } else {
            setEssentialsError(true);
            setTimeout(() => setEssentialsError(false), 2000);
        }
    }
    const handleJoinTeamsWaitlist = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
        
        e.preventDefault();

        if (e.type === "keydown" ) {
            let keypress = e as React.KeyboardEvent;
            if (keypress.key !== 'Enter') {
                return;
            }            
        }
        
        if (isValidEmail(teamsEmail)) {
            setTeamsSuccess(true);
            setTimeout(() => setTeamsSuccess(false), 2000);
            addToWaitlist("teams", teamsEmail);
        } else {
            setTeamsError(true);
            setTimeout(() => setTeamsError(false), 2000);
        }
    }

    const addToWaitlist = (pricingPackage: string, email: string) => {

        dispatch(joinWaitlist({
            firstName: "",
            lastName: "",
            email: email,
            marketingSource: "pricing package: " + pricingPackage
        }));

        setStarterEmail("");
        setEssentialsEmail("");
        setTeamsEmail("");
    }

    return (
    <div className="pricingTierBox">
        <div className="pricingStarterBox"> 
            <div style={{ width: "90%", margin: "0px auto" }}>
                <div className="pricingStarterTitle">
                    starter
                </div>
                <div style={{ textAlign: "left", marginLeft: "10px", marginTop: "10px", color: "#495866", fontWeight: "400" }}>
                    Ideal for single-roadmap users and trial runs!
                </div>
                <div style={{ textAlign: "left", marginLeft: "10px", fontSize: "32pt" }}>
                    $0.00
                </div>
                <div style={{ textAlign: "left", marginLeft: "10px", color: "#495866", fontWeight: "400" }}>
                    Free forever
                </div>
                {starterError &&
                    <div className="error" style={{ marginTop: "5px", marginBottom: "-10px" }}>
                        Please enter a valid email
                    </div>
                }
                {starterSuccess &&
                    <div className="success" style={{ marginTop: "5px", marginBottom: "-10px" }}>
                        You're on the list!
                    </div>
                }
                <div style={{ textAlign: "left", marginTop: "20px" }}>
                    <input className="form-control" placeholder="Enter email address"
                        value={starterEmail}
                        onChange={(e) => handleEmailChangeStarter(e)} />
                </div>
                <button className="blueButton" onClick={(e) => handleJoinStarterWaitlist(e)} onKeyDown={(e) => handleJoinStarterWaitlist(e)}>
                    Join Waiting List
                </button> 
                <hr className="pricingDivider" />
                <div style={{ textAlign: "left" }}>
                    {/*
                    <div style={{ marginBottom: "10px" }}>
                        <div style={{ display: "inline-block", marginRight: "10px" }}>
                            <IoMdCheckmarkCircleOutline size={20} />
                        </div>
                        <div style={{ position: "relative", top: "2px", fontSize: "13pt", display: "inline-block" }}>
                            1 Workspace
                        </div>
                    </div>
                    */}
                    <div style={{ marginBottom: "10px" }}>
                        <div style={{ display: "inline-block", marginRight: "10px" }}>
                            <IoMdCheckmarkCircleOutline size={20} />
                        </div>
                        <div style={{ position: "relative", top: "2px", fontSize: "13pt", display: "inline-block" }}>
                            2 Roadmap Generations
                        </div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <div style={{ display: "inline-block", marginRight: "10px" }}>
                            <IoMdCheckmarkCircleOutline size={20} />
                        </div>
                        <div style={{ position: "relative", top: "2px", fontSize: "13pt", display: "inline-block" }}>
                            5 Collaborators 
                        </div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <div style={{ display: "inline-block", marginRight: "10px" }}>
                            <IoMdCheckmarkCircleOutline size={20} />
                        </div>
                        <div style={{ position: "relative", top: "2px", fontSize: "13pt", display: "inline-block" }}>
                            Projects, Chat, & More
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="pricingEssentialsBox"> 
            <div className="pricingEssentialsBoxAdjustment">
                <div style={mostPopularStyle}>
                    Most Popular
                </div>
            </div>
            <div style={{ width: "90%", margin: "0px auto" }}>
                <div className="pricingEssentialsTitle">
                    essentials
                </div>
                <div style={{ textAlign: "left", marginLeft: "10px", marginTop: "10px", color: "#495866", fontWeight: "400" }}>
                    Perfect for small teams and collaborative individuals!
                </div>
                <div style={{ textAlign: "left", marginLeft: "10px", fontSize: "32pt" }}>
                    $9.99
                </div>
                <div style={{ textAlign: "left", marginLeft: "10px", color: "#495866", fontWeight: "400" }}>
                    Per user, per month, billed monthly
                </div>
                {essentialsError &&
                    <div className="error" style={{ marginTop: "5px", marginBottom: "-10px" }}>
                        Please enter a valid email
                    </div>
                }
                {essentialsSuccess &&
                    <div className="success" style={{ marginTop: "5px", marginBottom: "-10px" }}>
                        You're on the list!
                    </div>
                }
                <div style={{ textAlign: "left", marginTop: "20px" }}>
                    <input className="form-control" placeholder="Enter email address" 
                        value={essentialsEmail}
                        onChange={(e) => handleEmailChangeEssentials(e)} />
                </div>
                <button className="blueButton" onClick={(e) => handleJoinEssentialsWaitlist(e)} onKeyDown={(e) => handleJoinEssentialsWaitlist(e)}>
                    Join Waiting List
                </button> 
                <hr className="pricingDivider" />
                <div style={{ textAlign: "left" }}>
                    {/*
                    <div style={{ marginBottom: "10px" }}>
                        <div style={{ display: "inline-block", marginRight: "10px" }}>
                            <IoMdCheckmarkCircleOutline size={20} color="#f1c40f" />
                        </div>
                        <div style={{ position: "relative", top: "2px", fontSize: "13pt", display: "inline-block" }}>
                            10 Workspaces
                        </div>
                    </div>
                    */}
                    <div style={{ marginBottom: "10px" }}>
                        <div style={{ display: "inline-block", marginRight: "10px" }}>
                            <IoMdCheckmarkCircleOutline size={20} color="#f1c40f" />
                        </div>
                        <div style={{ position: "relative", top: "2px", fontSize: "13pt", display: "inline-block" }}>
                            20 Roadmap Creations
                        </div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <div style={{ display: "inline-block", marginRight: "10px" }}>
                            <IoMdCheckmarkCircleOutline size={20} color="#f1c40f" />
                        </div>
                        <div style={{ position: "relative", top: "2px", fontSize: "13pt", display: "inline-block" }}>
                            10 Collaborators Per Roadmap
                        </div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <div style={{ display: "inline-block", marginRight: "10px" }}>
                            <IoMdCheckmarkCircleOutline size={20} />
                        </div>
                        <div style={{ position: "relative", top: "2px", fontSize: "13pt", display: "inline-block" }}>
                            Projects, Chat, & More
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="pricingTeamsBox"> 
            <div style={{ width: "90%", margin: "0px auto" }}>
                <div className="pricingTeamsTitle">
                    teams
                </div>
                <div style={{ textAlign: "left", marginLeft: "10px", marginTop: "10px", color: "#495866", fontWeight: "400" }}>
                    Ideal for organizing work across multiple teams!
                </div>
                <div style={{ textAlign: "left", marginLeft: "10px", fontSize: "32pt" }}>
                    $19.99
                </div>
                <div style={{ textAlign: "left", marginLeft: "10px", color: "#495866", fontWeight: "400" }}>
                    Per user, per month, billed monthly
                </div>
                {teamsError &&
                    <div className="error" style={{ marginTop: "5px", marginBottom: "-10px" }}>
                        Please enter a valid email
                    </div>
                }
                {teamsSuccess &&
                    <div className="success" style={{ marginTop: "5px", marginBottom: "-10px" }}>
                        You're on the list!
                    </div>
                }
                <div style={{ textAlign: "left", marginTop: "20px" }}>
                    <input className="form-control" placeholder="Enter email address"
                        value={teamsEmail} 
                        onChange={(e) => handleEmailChangeTeams(e)} />
                </div>
                <button className="blueButton" onClick={(e) => handleJoinTeamsWaitlist(e)} onKeyDown={(e) => handleJoinTeamsWaitlist(e)}>
                    Join Waiting List
                </button> 
                <hr className="pricingDivider" />
                <div style={{ textAlign: "left" }}>
                    {/*
                    <div style={{ marginBottom: "10px" }}>
                        <div style={{ display: "inline-block", marginRight: "10px" }}>
                            <IoMdCheckmarkCircleOutline size={20} color="green" />
                        </div>
                        <div style={{ position: "relative", top: "2px", fontSize: "13pt", display: "inline-block" }}>
                            Unlimited Workspaces
                        </div>
                    </div>
                    */}
                    <div style={{ marginBottom: "10px" }}>
                        <div style={{ display: "inline-block", marginRight: "10px" }}>
                            <IoMdCheckmarkCircleOutline size={20} color="green" />
                        </div>
                        <div style={{ position: "relative", top: "2px", fontSize: "13pt", display: "inline-block" }}>
                            Unlimited Roadmap Creations
                        </div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <div style={{ display: "inline-block", marginRight: "10px" }}>
                            <IoMdCheckmarkCircleOutline size={20} color="green" />
                        </div>
                        <div style={{ position: "relative", top: "2px", fontSize: "13pt", display: "inline-block" }}>
                            100 Collaborators Per Roadmap
                        </div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <div style={{ display: "inline-block", marginRight: "10px" }}>
                            <IoMdCheckmarkCircleOutline size={20} color="green" />
                        </div>
                        <div style={{ position: "relative", top: "2px", fontSize: "13pt", display: "inline-block" }}>
                            User & Team Management
                        </div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <div style={{ display: "inline-block", marginRight: "10px" }}>
                            <IoMdCheckmarkCircleOutline size={20} />
                        </div>
                        <div style={{ position: "relative", top: "2px", fontSize: "13pt", display: "inline-block" }}>
                            Projects, Chat, & More
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default PricingTiers;