import React from 'react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../Core/hooks';
import { Link, useNavigate, useParams } from 'react-router-dom';
//import Footer from '../../Components/Footer';
//import Navigation from '../../Components/Navigation';
import { IconContext } from "react-icons";
import { FaUserCircle } from "react-icons/fa";
/*import { getProject, getProjectPublic, getProjectMembersPublic,
    getContainerPublic, getContainerMembersPublic
 } from "../../Store/Project";*/
 import { getProject, getContainerPublic, getContainerMembersPublic } from "../../Store/Project";
import Registration from '../../Modals/Registration';
import Login from '../../Modals/Login';
import NotFound404 from './NotFound404';

function ContainerInvite() {

    const dispatch = useAppDispatch();
    const {container, link } = useParams();
    const projectState = useAppSelector(getProject);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegistration, setShowRegistration] = useState(false);

    useEffect(() => {
        if (container && link) {
            dispatch(getContainerPublic({ containerId: parseInt(container), link: link }));
            dispatch(getContainerMembersPublic({ containerId: parseInt(container), link: link }));
        }
    }, []);

    const toggleLogin = (visible: boolean) => {
        setShowRegistration(false);
        setShowLogin(visible);
    }
 
    const toggleRegistration = (visible: boolean) => {
        setShowLogin(false);
        setShowRegistration(visible);
    }

    let containerMembers = projectState.containerMembers.filter(c => c.active);
    const containerOwner = containerMembers.find(o => o.memberType?.toString() === 'owner');

    const avatars = containerMembers.map(m => {
        return (
            <div style={{ display: "inline", marginRight: "1px" }}>
                {m.avatar !== "" && m.avatar !== undefined ?
                    <img className="imgSmallAvatar" src={m.avatar} alt={containerOwner?.firstName + " " + containerOwner?.lastName} />
                :
                    <div style={{ display: "inline-block" }} className="imgSmallAvatar">
                        <IconContext.Provider value={{ size: "35", color: "#000", className: "avatarIcon" }}>
                            <FaUserCircle />
                        </IconContext.Provider>
                    </div>
                }
            </div>
        )
    });

    /* We use Container not found. errror below as well because if the user is logged in, but visits an
       invalid share link, the projects array will still load all their projects and thus would show
       them an invalid share page, so we also use the error which will be last thrown by the dispatches
       in this page. */

    return (
        <>
            {projectState.containers.length > 0 && projectState.errorMessage !== "Container not found." ?
                <div className="pt-4 text-center">
                    <div style={{ paddingBottom: "10px" }}>
                        <img src="/images/herdr-logo-cat-only.png" style={{ width: "80px" }} alt="Herdr" />    
                    </div>
                    <div style={{ fontSize: "18pt" }}>
                        <span style={{ fontWeight: "bold" }}>
                            {containerOwner?.firstName + " " + containerOwner?.lastName}
                        </span> 
                        <span>
                            &nbsp;shared&nbsp;
                        </span>
                        <span style={{ fontWeight: "bold" }}>
                            {projectState.containers[0].name}
                        </span>
                        <span>
                            &nbsp;with you.
                        </span>
                    </div>
                    <div style={{ marginTop: "35px" }}>
                        {avatars}
                    </div>
                    <div style={{ marginTop: "35px" }}>
                        Join {containerOwner?.firstName + " " + containerOwner?.lastName} and others
                        in collaborating on this project.
                    </div>
                    <div style={{ textAlign: "center", marginTop: "35px", marginBottom: "35px" }}>
                        <div className="inviteBtnOrange" onClick={e => toggleRegistration(true)}>
                            Sign Up
                        </div>
                        <div className="inviteBtnGray" onClick={e => toggleLogin(true)}>
                            Login
                        </div>
                    </div>
                </div>
            :
                <NotFound404 />
            }

            <Login
                visible={showLogin}
                visibleHandler={toggleLogin}
                projectId={null}
                containerId={(container) ? parseInt(container) : null}
                link={link}
            />
            <Registration
                visible={showRegistration}
                visibleHandler={toggleRegistration}
                projectId={null}
                containerId={(container) ? parseInt(container) : null}
                link={link}
            />
        </>
    );
}

export default ContainerInvite;