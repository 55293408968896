import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../Core/hooks';
import { getUser, sendRecoveryLink } from '../../Store/Auth';
import { isValidEmail } from '../../Helpers/isValidEmail';

function Forgot() {

    const dispatch = useAppDispatch();
    const user = useAppSelector(getUser);
    const [email, setEmail] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [success, setSuccess] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (formSubmitted) {
            if (user.status === "idle"){
                setSuccessMessage("A recovery link has been sent to your email");
                setSuccess(true);
                setTimeout(() => setSuccess(false), 2000);
                setFormSubmitted(false);
                setEmail("");
            } else if (user.status === "failed") {
                setError(true);
                showError(user.errorMessage);
                setFormSubmitted(false);
            }
        }
    }, [user.status]);

    const showError = (error: string) => {
        setError(true);
        setErrorMessage(error);
        setFormSubmitted(false);
        setTimeout(() => setError(false), 2000);
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }

    const handleSendRecoveryLink = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
        
        e.preventDefault();

        if (e.type === "keydown" ) {
            let keypress = e as React.KeyboardEvent;
            if (keypress.key !== 'Enter') {
                return;
            }            
        }

        if (!isValidEmail(email)) {
            showError("Please enter a valid email");
        } else {
            setFormSubmitted(true);
            dispatch(sendRecoveryLink(email));
        }
    }

    return (
    <div style={{ display: "flex", alignItems: "top" }}>
        <div className="loginPageLeft">
            <div style={{ width: "100%" }}>
                <div className="mobileOnlyLogo">
                    <Link to="/" className="text-white fw-bold">
                        <div>
                            <img className="logoCat" src="/images/herdr-orange-logo-cat-v1.png" alt="herdr.io" />
                        </div>
                        <div style={{ position: 'relative', top: "-2px", left: '10px'}}>
                            <img className="pb-2 pt-2 logoText" src="/images/logo-black-text.png" alt="herdr.io" />
                        </div>
                    </Link>
                </div>
                <h1 style={{ fontSize: "24pt", textAlign: "center" }}>
                    Can't Sign-in?
                </h1>
                <div style={{ width: "80%", margin: "16px auto 0px auto", textAlign: "center" }}>
                    We'll send a recovery link to
                </div>
                <div style={{ width: "80%", margin: "30px auto 0px auto" }}>
                    {error &&
                        <div className="error" style={{ marginTop: "10px" }}>
                            {errorMessage}
                        </div>
                    }
                    {success &&
                        <div className="success" style={{ marginTop: "10px" }}>
                            {successMessage}
                        </div>
                    }
                    <input type="input" style={{ marginTop: "10px" }} className="form-control" placeholder="Email Address" 
                        value={email}
                        onChange={(e) => handleEmailChange(e)} />
   
                    <button className="orangeButton" style={{ width: "100%", marginTop: "10px" }}
                        onClick={(e) => handleSendRecoveryLink(e)} onKeyDown={(e) => handleSendRecoveryLink(e)}>
                        Send Recovery Link
                    </button>
                    <div style={{ marginTop: "10px", textAlign: "left", fontWeight: "400", fontSize: "11pt" }}>
                        <Link to="/login" className="blackLink">
                            Return to sign-in
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="loginPageRight">
            <div style={{ display: "inline-block", width: "100%", verticalAlign: "top" }}>
                <div style={{ color: "#fff", textAlign: "center", fontSize: "18pt" }}>
                    Empowering small teams to create big things
                </div>
                <div style={{ textAlign: "center", color: "#cacfd2", marginTop: "20px", fontSize: "14pt" }}>
                    Herdr empowers small teams to transform ideas into successful products. 
                    Go from brainstorming to roadmap to action in minutes.
                </div>
                <div style={{ color: "#cacfd2", marginTop: "20px", fontSize: "14pt", textAlign: "center" }}>
                    <strong>Sign-in or try us for free!</strong>
                </div>
                <div style={{ color: "#cacfd2", marginTop: "20px", fontSize: "14pt", textAlign: "center" }}>
                    <img src='/images/productivity-app.png' style={{ width: "100%" }} />
                </div>
            </div>
        </div>
    </div>
    );
}

export default Forgot;