import React, { useState, useEffect, CSSProperties } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { getUser } from '../Store/Auth';
import { useAppSelector } from '../Core/hooks';

interface Props {
    style?: CSSProperties;
}

function Footer(props: Props) {

    const user = useAppSelector(getUser);
    const [logoPath, setLogoPath] = useState("/");

    useEffect(() => {
        if (user.accessToken !== "") {
            setLogoPath("/members");
        }else {
            setLogoPath("/");
        }
    }, [user.accessToken]);

    const currentYear = format(new Date(), 'y');
    let style: CSSProperties = (props.style) ? props.style : {};

    return (
        <div style={style}>
            <div className="subfooter">
                <div className="subfooterInner">
                    <div className="footerLeft">
                        <div style={{ textAlign: "left", marginBottom: "20px" }}>
                            <Link to={logoPath} className="text-white fw-bold fs-4">
                                <div>
                                    <img className="pb-2 pt-2" style={{ width: "100px" }} src="/images/herdr-orange-logo-cat-v1.png" alt="herdr.io" />
                                </div>
                                <div style={{ position: 'relative', top: '-15px', marginBottom: "-15px" }}>
                                    <img className="pb-2 pt-2" style={{ width: "100px" }} src="/images/herdr-white-logo-text-v1.png" alt="herdr.io" />
                                </div>
                            </Link>
                        </div>
                        <div style={{ textAlign: "left", marginBottom: "20px", fontSize: "14pt", color: "#cacfd2" }}>
                            Herdr empowers small teams to transform ideas into successful products. 
                            Go from brainstorming to roadmap to action in minutes. Try it free today!
                        </div>
                        <div style={{ textAlign: "left", marginBottom: "20px" }}>
                            <a href="https://www.facebook.com/herdrapp" target="_BLANK" className="fa fa-facebook">
                            </a>
                            <a href="https://www.instagram.com/herdrapp" target="_BLANK" className="fa fa-instagram">
                            </a>
                            <a href="https://www.linkedin.com/company/herdrapp" target="_BLANK" className="fa fa-linkedin">
                            </a>
                            <a href="https://www.youtube.com/@Herdrapp" target="_BLANK" className="fa fa-youtube">
                            </a>
                            <a href="https://www.reddit.com/user/herdrapp" target="_BLANK" className="fa fa-reddit">
                            </a>
                        </div>
                    </div>
                    <div className="footerMiddle">
                        <div style={{ textAlign: "left", marginBottom: "20px", fontSize: "20pt" }}>
                            Navigation
                        </div>
                        <div style={{ textAlign: "left", marginBottom: "20px", fontSize: "16pt", color: "#cacfd2" }}>
                            <div style={{ marginBottom: "20px" }}>
                                <a className="footerLink" href="https://blog.herdr.io/category/how-to/">
                                    How To
                                </a>
                            </div>
                            <div>
                                <a className="footerLink" href="https://blog.herdr.io">
                                    Blog
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footerRight">
                        <div style={{ textAlign: "left", marginBottom: "20px", fontSize: "20pt" }}>
                            Legal
                        </div>
                        <div style={{ textAlign: "left", fontSize: "16pt", color: "#cacfd2" }}>
                            <div style={{ marginBottom: "20px" }}>
                                <a className="footerLink" href="/privacy">
                                    Privacy Policy
                                </a>
                            </div>
                            <div>
                                <a className="footerLink" href="/terms">
                                    Terms of Service
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="text-center pb-3 pt-3">
                <hr style={{ margin: "20px auto 40px auto", color: "#F8F9FA", borderWidth: "1.5", 
                    width: "80%" }} />
                <div className="disclaimer">
                    &copy; {currentYear} Burdette Thomas Company LLC. All rights reserved.
                </div>
            </footer>
        </div>
    );
}

export default Footer;