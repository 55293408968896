import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../Core/hooks';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';

function NonProfit() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navigation />
            <div className="pageHeader">
                <div className="standardPublicPage60">
                    <h1 className="homePageHeader">
                        Focus on What Matters: Making an Impact
                    </h1>  
                </div>
            </div>
            <div className="standardPublicPage60"> 
                <div className="standardPublicParagraph">
                    Running a small nonprofit means wearing many hats - managing initiatives, coordinating 
                    community events, handling volunteers, and meeting fundraising goals. With limited 
                    resources and countless responsibilities, staying organized can feel overwhelming. 
                    Complex tools only add to the workload, pulling your attention away from what truly 
                    matters: <b>creating meaningful change.</b> 
                </div>
                <div className="standardPublicParagraph">
                    Our platform is here to help you <b>stay on track without added complexity</b>. 
                    Manage tasks, streamline communication, and track progress effortlessly, so you can 
                    focus on making a difference in the communities you serve.  
                </div>
                <h2 className="standardPublicH2">Simplify Initiative Management</h2>
                <div className="standardPublicParagraph"> 
                    Whether you're running multiple programs or a single campaign, our tool makes it easy to 
                    keep everything organized. Break projects into manageable tasks, assign responsibilities, 
                    and monitor progress - all from one intuitive platform.  
                </div>
                <h2 className="standardPublicH2">Coordinate Community Events With Ease</h2>
                <div className="standardPublicParagraph"> 
                    Planning events is a critical part of nonprofit work, but it can also be one of the most 
                    stressful. From scheduling to volunteer management, our tool keeps every detail in check. 
                    Ensure your events run smoothly without last - minute chaos.  
                </div>
                <h2 className="standardPublicH2">Track Progress Across Programs</h2>
                <div className="standardPublicParagraph"> 
                        Stay informed about the status of all your initiatives at a glance. Whether it's a fundraising 
                        drive, a community outreach program, or a grant application, our platform gives you a clear 
                        overview of progress and upcoming deadlines.  
                </div>
                <h2 className="standardPublicH2">Streamline Team Collaboration</h2>
                <div className="standardPublicParagraph">  
                    Nonprofits thrive on teamwork, but miscommunication can lead to inefficiency. Our platform 
                    provides a centralized hub where your team can share updates, provide feedback, and stay 
                    aligned—no matter where they're located.  
                </div>
                <h2 className="standardPublicH2">Eliminate Complexity</h2>
                <div className="standardPublicParagraph"> 
                    Traditional project management tools can be overwhelming, especially for small nonprofits 
                    with limited bandwidth. We've removed the unnecessary features and focused on what you truly 
                    need: a simple, effective way to stay organized and on track.  
                </div>
                <h2 className="standardPublicH2">Focus on Your Mission</h2>
                <div className="standardPublicParagraph">  
                    Your nonprofit's mission is too important to get bogged down by administrative tasks. By 
                    simplifying your day-to-day operations, our platform frees up your time and energy to 
                    focus on the big picture: <b>making a meaningful impact</b>.  
                </div>
                <h2 className="standardPublicH2">Designed With Nonprofits in Mind</h2>
                <div className="standardPublicParagraph"> 
                    We understand the unique challenges small nonprofits face. That's why our platform is: 
                </div>
                <ul className="standardPublicParagraph">
                    <li><b>User-Friendly:</b> Easy for staff and volunteers to use without extensive training.</li>
                    <li><b>Cost-Effective:</b> Affordable pricing tailored for nonprofits.</li>  
                    <li><b>Customizable:</b> Flexible enough to adapt to your specific needs.</li>  
                </ul>
                <div className="standardPublicParagraph">
                    With our tool, managing your nonprofit becomes simpler and more efficient, allowing you to 
                    dedicate more time to your mission. Focus on what truly matters - <b>creating positive change 
                    in your community</b>. 
                </div>

                <div className="standardPublicParagraph">
                    <Link to="" onClick={(e) => { e.preventDefault(); navigate("/") }}>
                        Start your free trial today
                    </Link>
                    &nbsp;and see how we can help your nonprofit thrive.
                </div>
            </div>
            <Footer />
        </>
    );
}

export default NonProfit;