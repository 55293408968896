import React, { useState } from 'react';
import { useAppSelector } from '../Core/hooks';
import { Link } from 'react-router-dom';
import { IoMapOutline } from "react-icons/io5";
import { VscTriangleDown } from "react-icons/vsc";
import { BiSearch } from "react-icons/bi";
import { RxAvatar } from "react-icons/rx";
import { LuKanban } from "react-icons/lu";
import Avatar from "../Components/Avatar";
import { getProject } from '../Store/Project';
import { SettingsContextParam } from "../Models/Requests/SettingsContextParam";
import SettingsContext from "../Components/SettingsContext";

function TopNav() {

    const projectState = useAppSelector(getProject);
    const [settingsContextParams, setSettingsContextParams] = useState<SettingsContextParam>();

    const popSettingsWindow = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        //closeContainer();
        setSettingsContextParams({ id: 1, event: e }); 
    }

    let currentMember = projectState.projectMembers.find(m => m.me && m.active);

    return (
        <>
        <div style={{ width: "100%", padding: "5px 15px", backgroundColor: "#fff", 
            borderTopRightRadius: "20px", display: "flex", alignItems: "center",
            justifyContent: "space-between"
            }}>
            <div style={{ display: "inline-block", marginRight: "auto" }}>
                <div style={{ display: "inline-block", paddingRight: "5px", position: "relative", top: "-2px" }}>
                    <IoMapOutline size={19} color="#c44f01" />
                </div>
                <div style={{ display: "inline-block", fontSize: "11pt", fontWeight: "600", paddingRight: "5px" }}>
                    Herdr Product Launch
                </div>
                <div style={{ display: "inline-block" }}>
                    <VscTriangleDown size={14} />
                </div>
                <div style={{ fontSize: "10pt" }}>
                    {/*
                    <div style={{ display: "inline-block", padding: "0px 10px 0px 0px" }}>
                        Members
                    </div>
                    */}
                    <div style={{ display: "inline-block", padding: "0px 10px 0px 0px" }}>
                        Calendar   
                    </div>
                    {/*
                    <div style={{ display: "inline-block", padding: "0px 10px" }}>
                        Chat   
                    </div>
                    */}
                    <div style={{ display: "inline-block", padding: "0px 10px",
                        color: "#c44f01",
                        fontWeight: "600",
                        textDecoration: "underline",
                        textUnderlineOffset: "10px",
                        textDecorationThickness: "2px",
                    }}>
                        Board   
                    </div>
                    <div style={{ display: "inline-block", padding: "0px 10px" }}>
                        List   
                    </div>
                    <div style={{ display: "inline-block", padding: "0px 10px" }}>
                        Settings   
                    </div>
                </div>
            </div>
            <div style={{ display: "flex",alignItems: "center", marginLeft: "10px" }}>
                <div style={{ display: "inline-block", position: "relative", left: "26px" }}>
                    <BiSearch color="gray" size={18} />
                </div>
                <div style={{ display: "inline-block", marginRight: "20px" }}>
                    <input type="text" placeholder="Search your work..." 
                        style={{ color: "gray", border: "1px solid #ededee", borderRadius: "30px",
                            width:"200px", fontSize: "10pt", padding: "5px 10px 5px 26px"
                        }}
                    />
                </div>
                <div style={{ display: "inline-block", 
                    fontSize: "8pt", marginRight: "20px" }}>
                    <div style={{ fontWeight: "bold" }}>Free Plan</div>
                    <div style={{ fontWeight: "bold", color: "#007AFF" }}>
                        Upgrade&nbsp;
                        <VscTriangleDown size={14} color="#007AFF" />
                    </div>
                </div>
                <div onClick={(e) => popSettingsWindow(e)} style={{ cursor: "pointer" }}>
                        {/*<RxAvatar size={30} color="gray" />*/}
                        <Avatar size="small" projectMember={currentMember} />
                        {/*
                    <Link to="" onClick={(e) => popSettingsWindow(e)}>
                        <Avatar size="small" projectMember={currentMember} />
                    </Link>*/}
                </div>
            </div>
        </div>
        <SettingsContext params={settingsContextParams} />
        </>
    );
}

export default TopNav;