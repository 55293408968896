import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../Core/hooks';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';

function AcademicResearchTeam() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navigation />
            <div className="pageHeader">
                <div className="standardPublicPage60">
                    <h1 className="homePageHeader">
                        Empower Academic Research Teams to Focus on Breakthroughs
                    </h1>
                </div>
            </div>
            <div className="standardPublicPage60">
                <div className="standardPublicParagraph">
                    Academic research teams thrive on innovation and discovery, but the administrative side 
                    of managing experiments, deadlines, and publications can often feel like an overwhelming 
                    distraction. Tracking multiple tasks, coordinating team efforts, and meeting submission 
                    timelines shouldn't stand in the way of progress.  
                </div>
                <div className="standardPublicParagraph">
                    Our platform is designed to help research teams <b>streamline workflows, track deadlines, 
                    and manage tasks effortlessly</b> - allowing you to focus on what truly matters: <b>your 
                    experiments and publications.</b>
                </div>
                <h2 className="standardPublicH2">Organize Research Workflows</h2>
                <div className="standardPublicParagraph"> 
                    From hypothesis to publication, research involves many moving parts. Our platform 
                    allows you to map out your entire workflow, assign tasks to team members, and track 
                    progress at every stage. Stay organized and keep your project moving forward smoothly.  
                </div>
                <h2 className="standardPublicH2">Stay on Top of Deadlines</h2>
                <div className="standardPublicParagraph">
                    Whether it's funding proposals, conference submissions, or journal deadlines, meeting 
                    critical milestones is essential. Our platform keeps all your deadlines in one place, 
                    with automated reminders to ensure nothing slips through the cracks.  
                </div>
                <h2 className="standardPublicH2">Collaborate Across Your Team</h2>
                <div className="standardPublicParagraph"> 
                    Research is a team effort, often involving collaborators across departments, institutions, 
                    or even countries. Our platform centralizes communication and document sharing, making it 
                    easy to exchange ideas, provide feedback, and stay aligned—no matter where your team is based.  
                </div>
                <h2 className="standardPublicH2">Simplify Task Management</h2>
                <div className="standardPublicParagraph"> 
                    Large projects often mean juggling a variety of tasks, from lab work and data analysis to 
                    grant writing and administrative paperwork. With our tool, you can break down complex 
                    projects into manageable tasks, delegate responsibilities, and ensure accountability at every step.  
                </div>
                <h2 className="standardPublicH2">Centralized Knowledge Sharing</h2>
                <div className="standardPublicParagraph">  
                    Keep all your research notes, documents, and findings in one easily accessible location. 
                    Avoid duplicated efforts and ensure that every team member has the information they need 
                    to contribute effectively.  
                </div>
                <h2 className="standardPublicH2">Focus on Impactful Research</h2>
                <div className="standardPublicParagraph"> 
                    By simplifying the logistics of managing your team and projects, our platform allows you 
                    to focus on what really drives results: designing experiments, analyzing data, and 
                    publishing impactful work. Spend less time on administrative details and more time 
                    pushing the boundaries of knowledge.  
                </div>
                <h2 className="standardPublicH2">Designed for Academic Research Teams</h2>
                <div className="standardPublicParagraph"> 
                    We understand the unique needs of research teams, which is why our platform is:  
                </div>
                <ul className="standardPublicParagraph">
                    <li><b>Flexible and Adaptable:</b> Works with the way your team operates, whether in the lab, field, or library.</li>  
                    <li><b>User-Friendly:</b> No steep learning curve, so you can get started quickly.</li>
                    <li><b>Collaborative:</b> Ideal for multi-disciplinary teams and cross-institutional projects.</li>
                </ul>
                <h2 className="standardPublicH2">Streamline Your Research Efforts</h2>
                <div className="standardPublicParagraph">
                    From initial planning to final publication, our tool is here to make your research 
                    process smoother and more efficient. Focus on driving discoveries and publishing 
                    your findings - let us handle the rest.  
                </div>
                <div className="standardPublicParagraph">
                    Ready to simplify your research workflows?&nbsp;
                    <Link to="" onClick={(e) => { e.preventDefault(); navigate("/") }}>
                        Start your free trial today and take your team's productivity to the next level.
                    </Link>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default AcademicResearchTeam;