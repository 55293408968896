import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../Core/hooks';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';

function TechStartupFounder() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navigation />
            <div className="pageHeader">
                <div className="standardPublicPage60">
                    <h1 className="homePageHeader">
                        Simplify Your Startup's Journey: From Idea to Execution
                    </h1>
                </div>
            </div>
            <div className="standardPublicPage60">
                <div className="standardPublicParagraph">
                    As a tech startup founder, you juggle multiple priorities, constantly pivoting 
                    between ideas, strategies, and tasks. With so many moving pieces, organizing your 
                    vision and planning your next steps can become overwhelming. That's where we come in.
                </div>
                <div className="standardPublicParagraph">
                    Our platform helps you <b>simplify the process of organizing ideas and planning</b> - without 
                    the complexity of traditional project management tools. We empower you to stay <b>focuse</b> 
                    on what truly matters and ensure your team is always <b>aligned</b> as you move from concept to execution.
                </div>
                <h2 className="standardPublicH2">Clear, Intuitive Organization</h2>
                <div className="standardPublicParagraph">
                    Gone are the days of tangled spreadsheets, endless meetings, and scattered notes. Our tool 
                    helps you easily capture and organize your ideas, breaking them down into manageable steps. 
                    With intuitive features designed for your specific needs, you can quickly shift between 
                    brainstorming, planning, and execution - keeping your vision intact at every stage.
                </div>
                <h2 className="standardPublicH2">Streamlined Planning</h2>
                <div className="standardPublicParagraph">
                    Planning for a startup is dynamic. You need flexibility, but you also need clarity. 
                    Our platform allows you to create and modify plans quickly, giving you the agility 
                    you need to pivot while staying on track. Break down tasks, assign priorities, and 
                    set deadlines - all in one place.
                </div>
                <h2 className="standardPublicH2">Focused Execution</h2>
                <div className="standardPublicParagraph">
                    It's easy to get lost in the weeds of execution when your team is fragmented. With our platform, 
                    you can stay on top of everything with ease. Your team's tasks and goals are clearly outlined, 
                    ensuring everyone knows exactly what to do next. Track progress in real time, spot bottlenecks 
                    early, and keep your focus on delivering results.
                </div>
                <h2 className="standardPublicH2">Stay Aligned, Every Step of the Way</h2>
                <div className="standardPublicParagraph">
                    Alignment is crucial for any startup. With so many moving parts, it's easy for teams 
                    to drift out of sync. Our tool ensures that everyone, from the product team to marketing 
                    and sales, stays aligned around common goals. Keep everyone on the same page, so your team 
                    can move faster and smarter - together.
                </div>
                <h2 className="standardPublicH2">Remove Complexity, Add Clarity</h2>
                <div className="standardPublicParagraph">
                    Traditional project management tools can be clunky and overcomplicated, especially for a 
                    fast-moving startup. We've removed the complexity and designed a platform that is easy 
                    to use, intuitive, and focused on what matters most - helping you grow your business. 
                    Spend less time managing chaos, and more time building your dream.
                </div>
                <div className="standardPublicParagraph">
                    With our solution, <b>organizing your ideas and planning for the future becomes a smooth, 
                    straightforward process</b>. Focus on what's important, align your team, and drive your startup 
                    from concept to execution with clarity and confidence.
                </div>
                <div className="standardPublicParagraph">
                    Ready to simplify your planning process?&nbsp;
                    <Link to="" onClick={(e) => { e.preventDefault(); navigate("/") }}>
                        Start your free trial today!
                    </Link>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default TechStartupFounder;