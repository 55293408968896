import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../Core/hooks';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';

function CompanyExecutives() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navigation />
            <div className="pageHeader">
                <div className="standardPublicPage60">
                    <h1 className="homePageHeader">
                        Manage Strategic Initiatives: Empowering Small and Mid-Size Company Executives
                    </h1>
                </div>
            </div>
            <div className="standardPublicPage60">
                <div className="standardPublicParagraph">
                    As a small or mid-size company executive, managing strategic initiatives can feel like 
                    juggling priorities across departments while staying focused on the big picture. Whether 
                    launching new products, executing marketing campaigns, or scaling operations, success 
                    depends on keeping your teams aligned, organized, and equipped for execution.  
                </div>
                <div className="standardPublicParagraph">
                    This page outlines the key challenges executives face and how to overcome them to drive 
                    growth and efficiency.  
                </div>
                <h2 className="standardPublicH2">Why Managing Strategic Initiatives Is Critical</h2>
                <div className="standardPublicParagraph">
                    Strategic initiatives are the backbone of your company’s growth and success. From launching 
                    innovative products to building brand awareness through marketing campaigns, every project 
                    contributes to achieving long-term goals. But with limited resources and competing priorities, 
                    small and mid-size companies often face challenges like:  
                </div>
                <ul className="standardPublicParagraph">
                    <li><strong>Fragmented Communication</strong>: Siloed teams leading to misalignment.</li>
                    <li><strong>Resource Constraints</strong>: Limited time, budget, or staffing.</li>  
                    <li><strong>Scaling Complexity</strong>: Expanding operations without losing agility.</li>  
                    <li><strong>Tracking Progress</strong>: Difficulty maintaining visibility across multiple initiatives.</li> 
                </ul>
                <div className="standardPublicParagraph">
                    <Link to="" onClick={(e) => { e.preventDefault(); navigate("/") }}>
                        Try Herdr for free
                    </Link>&nbsp;
                    and experience how our platform can transform the way your teams manage and 
                    deliver on strategic initiatives.
                </div>
            </div>
            <Footer />
        </>
    );
}

export default CompanyExecutives;