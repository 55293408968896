import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../Core/hooks';
import { getUser, register, socialLogin } from '../../Store/Auth';
import { isValidEmail } from '../../Helpers/isValidEmail';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import GoogleSSO from '../../Components/GoogleSso';
import { decodeToken } from '../../Helpers/DecodeToken';
/*
import { acceptProjectInvite, acceptProjectInvitePublic,
    acceptContainerInvite, acceptContainerInvitePublic
 } from '../Store/Project';
*/

function Register() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    //const toastId = useRef(0);
    const [email, setEmail] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [password, setPassword] = useState("");
    const user = useAppSelector(getUser);
    //const [queryParams] = useSearchParams();
    const [error, setError] = useState<boolean>(false);
    const [registrationSubmitted, setRegistrationSubmitted] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    // const [activationCode, setActivationCode] = useState<string | null>(null);

    useEffect(() => {
        //setActivationCode(queryParams.get('ac'));
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (registrationSubmitted) {
            if (user.accessToken !== "" && user.status === "idle"){
                //navigate('/members/home');
                navigate('/dash');
            } else if (user.accessToken === "" && user.status === "idle") {
                /* Stand alone registration, activation needed */
            } else if (user.status === "failed") {
                setError(true);
                showError(user.errorMessage);
                setRegistrationSubmitted(false);
            }
        }
    }, [user.status]);

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
    }

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
    }

    function processGoogleLogin(response: google.accounts.id.CredentialResponse){
        const payload = decodeToken(response.credential as string);
        if (payload) {
            setRegistrationSubmitted(true);
            dispatch(socialLogin({ 
                email: payload.email, 
                firstName: payload.given_name,
                lastName: payload.family_name, 
                platform: "google",
                method: "registration",
                token: response.credential,
                picture: payload.picture,
            }));
        } else {
            showError("Social login failed");
        }
    }


    const handleRegistration = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
        
        e.preventDefault();

        if (e.type === "keydown" ) {
            let keypress = e as React.KeyboardEvent;
            if (keypress.key !== 'Enter') {
                return;
            }            
        }

        if (!isValidEmail(email) || password === "" || firstName === "" || lastName === "") {
            showError("Please enter all fields");
        } else {
            setRegistrationSubmitted(true);
            dispatch(register({
                email: email,
                password: password,
                firstName: firstName,
                lastName: lastName,
            }));
        }
    }

    const showError = (error: string) => {
        setError(true);
        setErrorMessage(error);
        setRegistrationSubmitted(false);
        setTimeout(() => setError(false), 2000);
    }

    const toggleShowPassword = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    }

    return (
    <div style={{ display: "flex", alignItems: "top" }}>
        <div className="loginPageLeft">
            <div style={{ width: "100%" }}>
                <div className="mobileOnlyLogo">
                    <Link to="/" className="text-white fw-bold">
                        <div>
                            <img className="logoCat" src="/images/herdr-orange-logo-cat-v1.png" alt="herdr.io" />
                        </div>
                        <div style={{ position: 'relative', top: "-2px", left: '10px'}}>
                            <img className="pb-2 pt-2 logoText" src="/images/logo-black-text.png" alt="herdr.io" />
                        </div>
                    </Link>
                </div>
                {registrationSubmitted && user.status === "idle" && user.accessToken === "" ?
                    <div className="registration-success">
                        <strong>Thank you for registering to herd your cats</strong><br/><br/>
                        <p>
                        Please check your email for an account activation
                        link. You can close this window, click the link in your email, and you're all set to login 
                        and start taking names and kicking butt.
                        </p>
                    </div>
                :
                <>
                    <h1 style={{ fontSize: "24pt", textAlign: "center" }}>
                        Sign-up to continue
                    </h1>
                    <div style={{ width: "80%", margin: "30px auto 0px auto" }}>
                        <GoogleSSO buttonText="signin_with" loginHandler={processGoogleLogin} />
                        <div className="mt-2 text-center">
                            OR
                        </div>
                    </div>
                    <div style={{ width: "80%", margin: "16px auto 0px auto" }}>
                        {error &&
                            <div className="error" style={{ marginTop: "10px" }}>
                                {errorMessage}
                            </div>
                        }
                        <input type="input" style={{ marginTop: "10px" }} className="form-control" placeholder="First Name" 
                            value={firstName}
                            onChange={(e) => handleFirstNameChange(e)} />
                        <input type="input" style={{ marginTop: "10px" }} className="form-control" placeholder="Last Name" 
                            value={lastName}
                            onChange={(e) => handleLastNameChange(e)} />
                        <input type="input" style={{ marginTop: "10px" }} className="form-control" placeholder="Email Address" 
                            value={email}
                            onChange={(e) => handleEmailChange(e)} />
                        {!showPassword ?
                            <input type="password" style={{ marginTop: "10px" }} className="form-control" placeholder="Password"
                                value={password}
                                onChange={(e) => handlePasswordChange(e)} />
                        :
                            <input type="input" style={{ marginTop: "10px" }} className="form-control" placeholder="Password"
                                value={password}
                                onChange={(e) => handlePasswordChange(e)} />
                        }    
                        <div onClick={(e) => toggleShowPassword(e)} style={{
                            float: "right", position: "relative", top: "-32px", left: "-15px",
                            textAlign: "right", width: "20px"
                        }}>
                            {!showPassword ?
                                <IoEyeOutline color="gray" size={18} />
                            :
                                <IoEyeOffOutline color="gray" size={18} />
                            }
                        </div>
                        <button className="orangeButton" style={{ width: "100%", marginTop: "10px" }}
                            onClick={(e) => handleRegistration(e)} onKeyDown={(e) => handleRegistration(e)}>
                            Sign-up
                        </button>
                        <div className="loginPageSignup">
                            Already have an account?&nbsp;
                            <Link to="/login" className="blackLink">
                                Sign-in
                            </Link>
                        </div>
                    </div>
                </>
                }
            </div>
        </div>
        <div className="loginPageRight">
            <div style={{ display: "inline-block", width: "100%", verticalAlign: "top" }}>
                <div style={{ color: "#fff", textAlign: "center", fontSize: "18pt" }}>
                    Empowering small teams to create big things
                </div>
                <div style={{ textAlign: "center", color: "#cacfd2", marginTop: "20px", fontSize: "14pt" }}>
                    Herdr empowers small teams to transform ideas into successful products. 
                    Go from brainstorming to roadmap to action in minutes.
                </div>
                <div style={{ color: "#cacfd2", marginTop: "20px", fontSize: "14pt", textAlign: "center" }}>
                    <strong>Sign-up and try us for free!</strong>
                </div>
                <div style={{ color: "#cacfd2", marginTop: "20px", fontSize: "14pt", textAlign: "center" }}>
                    <img src='/images/productivity-app.png' style={{ width: "100%" }} />
                </div>
            </div>
        </div>
    </div>
    );
}

export default Register;